footer {
  margin: 3rem 0 0;
  content-visibility: auto;
  contain-intrinsic-size: 472px;
    .social-logos-container {
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
        margin: 2rem 0 0;
        .trustpilot-container {
            flex-basis: 35rem;
        }
    }

  .footer__container-links {
    padding: 6rem 0 3rem;
    background: color(gray, Gray-100);
    @include breakpoint(small only) {
      padding: 3rem 0;
    }

    ul {
      li {
        a {
          font-size: 1.3rem;
          line-height: 2.3rem;
        }

        &:first-of-type {
          @include head-font;
          font-weight: 600;
          font-size: 1.3rem;
          padding: 0 0 1.5rem;
          @include breakpoint(small only) {
            margin: 1rem 0 0;
          }
        }
      }
    }

    ._social-holder {
      display: flex;
      align-items: center;
      margin: 10rem 0 0;
      @include breakpoint(small only) {
        margin: 2rem 0 2rem;
        justify-content: flex-start;
      }

      a {
        @include transition;
        background-repeat: no-repeat;
        margin: 0 .5rem 0 0;
        width: 3rem;
        height: 3rem;
        @media only screen and (max-width: 768px) {
          width: 3rem;
          height: 3rem;
          background-size: contain;
          @include breakpoint(small only) {
            width: 3rem;
            height: 3rem;
          }
        }

        &:hover {
          text-decoration: none;
        }

        &._fb {
          background-image: url("data:image/svg+xml,%3Csvg height='30px' version='1.1' viewBox='0 0 60 60' width='30px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle/%3E%3Cdefs/%3E%3Cg fill='none' fill-rule='evenodd' id='black' stroke='none' stroke-width='1'%3E%3Cg id='facebook'%3E%3Cpath d='M30,60 C46.5685433,60 60,46.5685433 60,30 C60,13.4314567 46.5685433,0 30,0 C13.4314567,0 0,13.4314567 0,30 C0,46.5685433 13.4314567,60 30,60 Z' fill='%23000000' id='Facebook'/%3E%3Cpath d='M25.4622239,47.314313 L25.4622239,29.9989613 L21.9432889,29.9989612 L21.9432889,24.2593563 L25.4622239,24.2593563 L25.4622239,20.7883864 C25.4622239,16.1066422 26.8619948,12.730619 31.9881023,12.730619 L38.0863595,12.730619 L38.0863595,18.45844 L33.7922546,18.45844 C31.6418944,18.45844 31.1518737,19.8873763 31.1518737,21.3837609 L31.1518737,24.2593553 L37.7694231,24.2593558 L36.8661728,29.9989613 L31.1518737,29.9989613 L31.1518737,47.3143123 L25.4622239,47.314313 Z' fill='%23FFFFFF'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

          &:hover {
            opacity: .4;
          }
        }

        &._ig {
          background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' id='Layer_1' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cpath d='M314.757,147.525H197.243a49.717,49.717,0,0,0-49.718,49.718V314.757a49.718,49.718,0,0,0,49.718,49.718H314.757a49.718,49.718,0,0,0,49.717-49.718V197.243A49.717,49.717,0,0,0,314.757,147.525ZM256,324.391A68.391,68.391,0,1,1,324.391,256,68.391,68.391,0,0,1,256,324.391ZM327.242,201.58a16.271,16.271,0,1,1,16.27-16.271A16.271,16.271,0,0,1,327.242,201.58Z'/%3E%3Cpath d='M256,211.545A44.455,44.455,0,1,0,300.455,256,44.455,44.455,0,0,0,256,211.545Z'/%3E%3Cpath d='M256,0C114.615,0,0,114.615,0,256S114.615,512,256,512,512,397.385,512,256,397.385,0,256,0ZM389.333,312.5A76.836,76.836,0,0,1,312.5,389.333H199.5A76.837,76.837,0,0,1,122.666,312.5V199.5A76.836,76.836,0,0,1,199.5,122.667H312.5A76.836,76.836,0,0,1,389.333,199.5Z'/%3E%3C/svg%3E");

          &:hover {
            opacity: .4;
          }
        }
      }
    }

    .bottom-links {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 3rem 0 0;
        &:first-of-type {
            margin: 2rem 0 0;
        }
      @include breakpoint(small only) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
      }

      li {
        line-height: 2.3rem;

        a {
          display: inline-block;
          font-size: 1.3rem;
          line-height: 2.3rem;
          margin-right: 2rem;
          @include breakpoint(small only) {
            margin: 0;
          }
        }

        &:first-of-type {
          padding: 0;
        }
      }

      ._logo {
        margin-left: auto;
        @include breakpoint(small only) {
            margin: 20px auto 0 0;
        }

        a {
          display: inline-block;
          width: 15rem;
        }
      }
    }
  }
}
