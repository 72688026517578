input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  color: color(base, Antracite);
  opacity: 1;
}

:-ms-input-placeholder {
  color: color(base, Antracite);
}

::-ms-input-placeholder {
  color: color(base, Antracite);
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'], select {
  height: 3rem;
  line-height: 3rem;
  font-size: 1.2rem;
  padding: 0 1.2rem;
  border: .1rem solid color(gray, Gray-300);
  color: color(base, Black);
  &:disabled {
    cursor: not-allowed;
    background-color: #FAFAFA;
    opacity: 1;
  }
  @include breakpoint(medium) {
    line-height: 3rem;
    height: 3rem;
    font-size: 1.2rem;
  }
}

select {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='18px' height='9px' viewBox='0 0 18 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-4' transform='translate(-137.000000, -14.000000)' stroke='%230A233D' stroke-width='0.486'%3E%3Cpolyline id='Stroke-1' points='154.9039 15.0044 145.9389 22.3754 137.2149 15.0044'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 1.5rem;
  background-position: right center;
  padding: 0 3rem;
}

.field-error {
  font-size: 1.2rem;
  display: block;
  margin-top: .5rem;
  font-weight: normal;
  color: color(alert, Error);
}

.field-success {
  font-size: 1.2rem;
  display: block;
  margin-top: .5rem;
  font-weight: normal;
  color: color(alert, Success);
}

.field__container {
  margin: 0 0 1.5rem;
  label {
    color: color(base, Black);
    font-size: 1.3rem;
    margin: 0 0 .5rem;
  }
  .input__group {
    display: flex;
    padding: 1rem 0 0;
    label {
      margin-right: 1rem;
      font-size: 1.4rem;
      cursor: pointer;
    }
    input {
      width: auto;
      margin-right: .5rem;
    }
  }
  &.flex {
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      input {
        margin-right: .5rem;
      }
    }
  }
}
