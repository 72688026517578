.home {
    .breadcrumbs {
        display: none;
    }

    .home__callout-container {
        display: flex;
        flex-wrap: wrap;

        .callout-first {
            width: 50%;
            position: relative;

            img {
                max-height: 800px;
                width: 100%;
                object-fit: cover;
            }

            a {
                position: absolute;
                display: block;
                font-size: 4.8rem;
                color: color(base, White);
                width: 24rem;
                line-height: 4.8rem;
                font-weight: 300;
                left: 50%;
                top: 25%;
                transform: translate(-100%, -50%);
                @include breakpoint(small only) {
                    transform: translate(0%, -50%);
                    font-size: 3rem;
                    width: 14rem;
                    line-height: 3rem;
                    left: 10%;
                }
            }

            @include breakpoint(small only) {
                width: 100%;
            }
        }

        .callout-second {
            width: 50%;
            position: relative;

            img {
                max-height: 800px;
                width: 100%;
                object-fit: cover;
            }

            h3 {
                position: absolute;
                left: 10%;
                top: 25%;
                transform: translate(0%, -50%);
                font-size: 9.6rem;
                line-height: 8.5rem;
                font-weight: 600;
                color: color(base, White);
                width: 20rem;
                @include breakpoint(small only) {
                    font-size: 3rem;
                    line-height: 3rem;
                }

            }

            a {
                position: absolute;
                display: block;
                font-size: 4rem;
                color: color(base, White);
                width: 16rem;
                line-height: 4.8rem;
                font-weight: 400;
                border-bottom: 3px solid color(base, White);
                padding: 0 0 1rem;
                left: 10%;
                top: 48%;
                transform: translate(0%, -50%);
                @include breakpoint(small only) {
                    font-size: 3rem;
                    line-height: 3rem;
                    width: 12rem;
                    top: 38%;
                }
            }

            @include breakpoint(small only) {
                width: 100%;
            }
        }
    }
}

.home__hero-container {
    position: relative;

    .home__hero-slider {
        img {
            width: 100%;
            height: 640px;
            object-fit: cover;
            object-position: left center;
            transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
            @include breakpoint(medium only) {
                height: 500px;
            }

            &:hover {
                transform: scale(1.05);
                @include breakpoint(small only) {
                    transform: none;
                }
            }

            @include breakpoint(small only) {
                height: 100vh;
                min-height: -webkit-fill-available;
                min-height: -moz-fill-availble;
            }
        }

        .home__slider-container {
            a {
                position: relative;
                display: block;
                overflow: hidden;

                > span:nth-of-type(1) {
                    color: color(base, White);
                    cursor: pointer;
                    font-size: 5rem;
                    left: 10%;
                    position: absolute;
                    top: 25%;
                    font-weight: 400;
                    width: 70%;
                    line-height: 5rem;
                }

                > span:nth-of-type(2) {
                    position: absolute;
                    top: 50%;
                    left: 10%;
                    font-size: 3rem;
                    cursor: pointer;
                    color: color(base, White);
                    text-decoration: underline;
                    line-height: 6rem;
                    font-weight: 500;
                    text-underline-offset: 25px;
                    text-decoration-thickness: 3px;
                }

                .highlighted {
                    font-family: "STIXGeneral", sans-serif;
                    font-size: 5rem;
                    line-height: 5rem;
                    color: color(base, White);
                    font-style: italic;
                }
            }
        }
    }

    .home-scroll-down-container {
        position: absolute;
        bottom: 75px;
        z-index: 1;
        width: 175px;
        transform: translateX(-50%);
        left: 50%;
        text-align: center;

        a {
            font-weight: 900;
            font-size: 18px;
            color: white;
            text-shadow: 1px 1px 5px color(gray, Gray-200);
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: 55px;
                transform: translateX(50%);
                bottom: -35px;
                height: 20px;
                width: 20px;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='15px' viewBox='0 0 26 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='chevron-down-svgrepo-com-(1)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M14.365623,14.3248532 C13.4654273,15.2250489 12.0906719,15.2250489 11.1888454,14.3248532 C11.1497065,14.2791911 11.1497065,14.2791911 11.1497065,14.2791911 L0.640900196,3.81604697 C-0.213633399,2.91585127 -0.213633399,1.54598826 0.640900196,0.645792564 C1.54109589,-0.215264188 2.91585127,-0.215264188 3.8111546,0.645792564 L12.7756034,9.6493803 L21.6992825,0.730593607 C22.553816,-0.123939987 23.9742335,-0.123939987 24.8303979,0.730593607 C25.6849315,1.59165036 25.6849315,3.00554468 24.8303979,3.86170907 L14.365623,14.3248532 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 20px;
            }
        }
    }
}

.home__categories-container {
    @include breakpoint(small only) {
        position: relative;
    }

    .catalog__category-list {
        margin: 6rem 0 0;
        @include breakpoint(small only) {
            margin: 3rem 0 0;
        }

        ._header {
            h2 {
                font-size: 2.4rem;
                line-height: 2.8rem;
                padding: 0 0 2rem;
            }
        }

        ul {
            padding: 2rem 0 0;

            .swiper-container {
                margin: 0;
                width: 100%;

                .swiper-slide {
                    width: calc(33.3334% - 15px);
                    @include breakpoint(large) {
                        //width: calc(25% - 20px);
                        width: calc(22.2223% - 20px);
                    }
                    @include breakpoint(small only) {
                        width: calc(50% - 10px);
                    }
                }
            }

            &:before, &:after {
                transition-duration: 400ms;
                transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
                content: "";
                opacity: 0;
                transition-property: opacity;
                position: absolute;
                height: 100%;
                width: 300px;
                top: 0;
                pointer-events: none;
            }

            &:before {
                left: 0;
                z-index: 2;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.47) 33%, rgba(255, 255, 255, 0.79) 56%, rgba(255, 255, 255, 0.95) 80%, #ffffff 100%);
            }

            &:after {
                right: 0;
                z-index: 2;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.47) 33%, rgba(255, 255, 255, 0.79) 56%, rgba(255, 255, 255, 0.95) 80%, #ffffff 100%);
            }
        }

        .slider__controls {
            position: absolute;
            right: 0;
            top: 1.5rem;
            @include breakpoint(small only) {
                display: none;
            }

            .swiper-button-next {
                right: 0;
                z-index: 1;

                &:after {
                    font-size: 1.6rem;
                    font-weight: 900;
                    color: color(gray, Gray-200);
                }
            }

            .swiper-button-prev {
                right: 4.5rem;
                left: auto;
                z-index: 1;
                @include breakpoint(small only) {
                    right: 4rem;
                }

                &:after {
                    font-size: 1.6rem;
                    font-weight: 900;
                    color: color(gray, Gray-200);
                }
            }
        }

        .category__list-item {
            a {
                border: none;
                padding: 0;
                @include breakpoint(small only) {
                    position: relative;
                }

                &:hover {
                    border: none;
                    box-shadow: none;

                    img {
                        cursor: pointer;
                    }

                    h2 {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .image_holder {
                    img {
                        width: 100%;
                        height: 320px;
                        object-fit: cover;
                        margin: 0 auto 0.5rem;
                        transition: transform .3s;
                        @include breakpoint(small only) {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                .category__holder {
                    @include breakpoint(small only) {
                        position: absolute;
                        bottom: 5px;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    .category__name {
                        h2 {
                            font-size: 1.8rem;
                            font-weight: 600;
                            @include breakpoint(small only) {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.home__callout-second-container {
    position: relative;
    margin: 6rem 0;
    @include breakpoint(small only) {
        margin: 0;
    }

    .home__cta-container {
        h3 {
            margin: 0 0 2rem;
            font-size: 4.6rem;
            line-height: 4.8rem;

            strong {
                font-size: 4.6rem;
                line-height: 4.8rem;
                font-weight: 600;
            }
        }

        form {
            display: flex;
            flex-wrap: nowrap;
            input {
                height: 4rem;
                line-height: 4rem;
                width: 80%;
            }

            button {
                display: inline-block;
                height: 4rem;
                line-height: 4rem;
                font-size: 1.6rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, White)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left 1.8rem center;
                background-size: 1.6rem;
                border-radius: 0 3px 3px 0;

                &:hover {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, Green)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
                }
            }
        }
    }

    .callout-right {
        @include breakpoint(small only) {
            background: color(base, Green);
            padding: 60px 0 0;
            position: relative;
        }

        img {
            max-height: 600px;
            width: 100%;
            height: 600px;
            object-fit: cover;
            @include breakpoint(small only) {
                width: 80%;
                max-height: 500px;
            }
        }
    }

    .callout-magazine-cta {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: color(base, Green);
        width: 350px;
        height: 350px;
        border-radius: 50%;
        box-shadow: rgb(0 0 0 / 12%) 0 0.125rem 0.25rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint(small only) {
            position: relative;
            top: 0;
            transform: none;
            padding: 40px 0 100px;
            width: 100%;
            left: 0;
            border-radius: 0;
            height: 50%;
        }

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                color: color(base, White);
                display: inline-block;
                width: 90%;
                font-size: 4rem;
                line-height: 4rem;
                text-align: center;
                @include breakpoint(small only) {
                    text-align: left;
                    width: 80%;
                }
            }

            a {
                width: 100%;
                display: block;
                font-family: 'STIXGeneral', sans-serif;
                font-size: 4rem;
                color: color(base, White);
                line-height: 4rem;
                text-align: center;
                @include breakpoint(small only) {
                    text-align: left;
                    width: 80%;
                    font-style: italic;
                }
            }
        }
    }
}

.home_styles-second-container {
    margin: 6rem 0 0;
    ._header {
        position: relative;
        h2 {
            font-size: 2.4rem;
            line-height: 2.8rem;
            padding: 0 0 2rem;
        }
        .slider__controls {
            position: absolute;
            right: 0;
            top: 1.5rem;
            @include breakpoint(small only) {
                display: none;
            }

            .swiper-button-next {
                right: 0;
                z-index: 1;

                &:after {
                    font-size: 1.6rem;
                    font-weight: 900;
                    color: color(gray, Gray-200);
                }
            }

            .swiper-button-prev {
                right: 4.5rem;
                left: auto;
                z-index: 1;
                @include breakpoint(small only) {
                    right: 4rem;
                }

                &:after {
                    font-size: 1.6rem;
                    font-weight: 900;
                    color: color(gray, Gray-200);
                }
            }
        }
    }

    .callout-styles {
        .swiper-container {
            height: 100%;
        }

        .home__slider-container {
            height: 100%;
            position: relative;

            .slider__controls {
                @include breakpoint(small only) {
                    display: none;
                }
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                @include breakpoint(small only) {
                    height: 600px;
                }
            }
        }
    }

    .callout-magazine {
        width: 100%;
        height: auto;
    }
}

.home_new-products-container {
    margin: 6rem 0;
    @include slider;

    h2 {
        font-size: 2.4rem;
        line-height: 2.8rem;
    }
    .slider__controls {
        .swiper-button-prev {
            right: 4.5rem !important;
        }
        .swiper-button-next {
            right: 0 !important;
        }
    }
}

.home_description-container {
    margin: 0 0 6rem;
    @include breakpoint(small only) {
        margin: 0 0 4rem;
    }

    .home-description {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
        @include breakpoint(small only) {
            column-count: 1;
            column-gap: 0;
        }

        p {
            font-size: 1.6rem;
            line-height: 2.4rem;
            @include breakpoint(small only) {
                margin: 1.6rem 0;
            }
        }
    }
}

.callout-location {
    display: flex;
    align-items: center;
}
.home__cta-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    //padding: 6rem 0 0 0;
    @include breakpoint(small only) {
        width: 100%;
        padding: 0 0 6rem;
    }

    .store__locator-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0 5rem;
        @include breakpoint(small only) {
            padding: 0 0 6rem;
        }

        h2 {
            font-size: 4.8rem;
            line-height: 4.6rem;
            font-family: "Work Sans", sans-serif;
            font-weight: 400;
            padding: 0 0 2.5rem;

            span {
                font-size: 4.8rem;
                line-height: 4.6rem;
                font-weight: 600;
            }
        }

        select {
            width: 60%;
            line-height: 4rem;
            height: 4rem;
        }
    }

    .newsletter__container {
        padding: 4rem 0 0;
        width: 100%;
        @include breakpoint(small only) {
            padding: 6rem 0 0;
        }
        form {
            display: block;
        }

        input {
            width: 80%;
            line-height: 4rem;
            height: 4rem;
        }

        .newsletter__container-title {
            font-size: 1.8rem;
            line-height: 2.4rem;
            font-family: "Work Sans", sans-serif;
            font-weight: 600;
        }

        .newsletter__container-subtitle {
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin: 0 0 2.5rem
        }
    }
}
