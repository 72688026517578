.customer-account {
    padding: 3rem 0;
    @include breakpoint(small only) {
        padding: 0;
        margin: 1.5rem 0 0;
    }
    h1, h2, h3 {
        font-size: 2.4rem;
        margin: 0 0 1rem;
        font-weight: 600;
    }
    h3 {
        font-size: 1.6rem;
    }
    .breadcrumbs {
        display: none;
    }
    .customer__account-navigation_toggle {
        padding-left: 3.2rem !important;
        @include default-button;
        background-repeat: no-repeat;
        background-position: left .8rem center;
        background-size: 1.6rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-menu-2' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cline x1='4' y1='6' x2='20' y2='6' /%3E%3Cline x1='4' y1='12' x2='20' y2='12' /%3E%3Cline x1='4' y1='18' x2='20' y2='18' /%3E%3C/svg%3E");
        margin: 0 0 1rem;
    }
    .customer__account-navigation {
        h3 {
            font-family: Work Sans,sans-serif;
            font-size: 2.4rem;
            font-weight: 500;
            line-height: 3rem;
            margin: 0 0 1rem;
        }
        ul li {
            @include breakpoint(medium) {
                a {
                    font-size: 1.3rem;
                    display: block;
                    line-height: 2.8rem;
                }
            }
            @include breakpoint(small only) {
                font-size: 2.4rem;
                line-height: 3rem;
            }
            &._active {
                a {
                    font-weight: 600;
                    pointer-events: none;
                }
            }
        }
    }
    ._date {
        margin-left: auto;
        line-height: 3.4rem;
        font-size: 1.2rem;
    }
    ._new-address {
        margin-left: auto;
    }
    .customer__block {
        .a-center {
            text-align: center;
        }
        .a-right {
            text-align: right;
        }
        table {
            width: 100%;
            margin: 0 0 2rem;
            tr {
                &:nth-child(even) {
                    background: #FAFAFA;
                }
                th {
                    text-align: left;
                    font-weight: 500;
                }
                th, td {
                    padding: .4rem .2rem;
                    ul li {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
    .order__totals {
        ul {
            margin-left: auto;
            li {
                display: flex;
                span, strong {
                    display: block;
                    flex: 1;
                    text-align: right;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    &:first-child {
                        width: 14rem;
                    }
                }
                strong {
                    font-size: 1.6prem;
                }
            }
        }
    }

    .customer__account_block {
        &._login {
            border: .1rem solid color(gray, Gray-100);
            padding: 2rem;
            border-radius: .3rem;
            margin-bottom: 1.5rem;
            ._forget {
                font-size: 1.3rem;
                font-weight: normal;
                margin-left: 1rem;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        ._usp {
            margin: 2rem 0;
            h3 {
                margin: 0 0 1rem;
            }
            li {
                line-height: 3rem;
                color: color(base, Black);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-check' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, Green)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5 12l5 5l10 -10' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 2.5rem;
                font-size: 1.3rem;
                @include breakpoint(small only) {
                    font-size: 1.2rem;
                }
            }
        }
    }
    ._intro {
        margin: 0 0 2rem;
    }
    ._header {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        a {
            margin-left: auto;
            font-size: 1.3rem;
            text-decoration: underline;
        }
        h2, h3 {
            margin: 0;
        }
    }
    .address__block {
        background: color(gray, Gray-100);
        padding: 1rem;
        margin: 0 0 1.5rem;
        > span {
            font-size: 1.4rem;
            line-height: 2.4rem;
        }
        address {
            font-size: 1.4rem;
            line-height: 2.4rem;
        }
        > a {
            display: block;
            font-size: 1.3rem;
            margin-top: 1rem;
        }
        ._actions {
            display: flex;
            margin: 1rem 0 0;
            a {
                display: block;
                font-size: 1.3rem;
                margin-right: 1rem;
            }
        }
    }
    .reward__history {
        table {
            table-layout: fixed;
            width: 100%;
            tr {
                line-height: 3rem;
            }
            th {
                text-align: left;
                font-weight: 600;
                padding: .5rem;
            }
            tr:nth-child(odd) {
                background-color: color(gray, Gray-100);
            }
            td {
                padding: .5rem;
            }
        }
    }
}
