@mixin messages($type) {
    font-size: 14px;
    display: inline-block;
    @if ($type == "warning") {
        background-color: #fcf8e3;
        border-color: #faf2cc;
        color: #8a6d3b;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#8a6d3b'%3E%3Cpath fill-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
    } @else if ($type == "error") {
        background-color: scale-color(color(alert, Error), $lightness: 90%);
        border: 1px solid scale-color(color(alert, Error), $lightness: 80%);
        color: color(alert, Error);
    } @else if ($type == "success") {
        background-color: #dff0d8;
        border-color: #d0e9c6;
        color: #3c763d;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#3c763d'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
    }
}

@mixin head-font-style {
    line-height: 2.8rem;
    font-size: 2.6rem;
    margin: 0 0 2rem;
    font-weight: 500;
    color: color(base, Black);
    @include breakpoint(small only) {
        line-height: 2.4rem;
        font-size: 2rem;
        margin: .5rem 0 1.5rem;
    }
}

@mixin primary-font-size {
    font-size: 1.4rem;
    line-height: 2.1rem;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin head-font {
    font-family: 'Work Sans', sans-serif;
}

@mixin secondary-font {
    font-family: 'STIXGeneral', sans-serif;
}

@mixin transition() {
    transition: all 0.3s ease;
}

@mixin shadow {
    box-shadow: 0 1px 5px 1px rgba(42, 42, 42, 0.1);
}

@mixin default-button {
    @include transition;
    font-size: 16px;
    display: inline-block;
    background-color: color(base, Green);
    border: 1px solid color(base, Green);
    background-repeat: no-repeat;
    color: color(base, White);
    height: 40px;
    border-radius: 3px;
    line-height: 40px;
    padding: 0 25px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &._chevron {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='16px' viewBox='0 0 10 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPage 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon stroke='%23FFFFFF' stroke-width='2' transform='translate(5.142833, 8.000000) scale(-1, 1) translate(-5.142833, -8.000000) ' points='7.99987377 2 2 7.99987377 7.99987377 14 8.2856662 13.7139551 2.57183733 7.99987377 8.2856662 2.2860449'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
        background-position: right 20px center;
        padding: 0 40px 0 20px;
        @include breakpoint(small only) {
            background-size: 9px;
        }
    }
    &._secondary {
        color: color(base, White);
        background-color: color(base, Antracite);
        border-radius: 0;
        &:hover {
            background-color: lighten(color(base, Antracite), 5%);
            color: color(base, White);
        }
    }
    @include breakpoint(small only) {
        height: 38px;
        line-height: 38px;
        font-size: 12px;
    }
    &:hover {
        background-color: color(base, White);
        color: color(base, Green);
        border: 1px solid color(base, Green);
        text-decoration: none;
    }
    &._cta {
        background-color: color(base, Cta);
        border: 1px solid transparent;
        box-shadow: inset 0 -2px 0 0 darken(color(base, Cta), 5%);
        &:hover {
            background-color: darken(color(base, Cta), 5%);
            box-shadow: inset 0 -2px 0 0 darken(color(base, Cta), 10%);
            color: color(base, White);
        }
        &:disabled {
            cursor: not-allowed;
            background: color(gray, Gray-100);
            box-shadow: inset 0 -2px 0 0 darken(color(gray, Gray-100), 5%);
            color: color(gray, Gray-200);
        }
    }
    &._outline {
        background-color: color(base, White);
        color: color(base, Green);
        border: 1px solid color(base, Green);
        text-decoration: none;
        &:hover {
            background-color: color(base, Green);
            background-repeat: no-repeat;
            color: color(base, White);
        }
        &:disabled {
            cursor: not-allowed;
            background: color(gray, Gray-100);
            box-shadow: inset 0 -2px 0 0 darken(color(gray, Gray-100), 5%);
            color: color(gray, Gray-200);
        }
    }
    &._small {
        height: 34px;
        line-height: 34px;
        font-size: 12px;
        padding: 0 10px;
    }
}

@mixin loading {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    left: -5px;
    right: -5px;
    top: 0;
    bottom: 0;
    z-index: 7;
    @include breakpoint(small only) {
        background: color(gray, Gray-100);
        left: auto;
        right: -8rem;
    }
    ._spinner {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='%23000' d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        width: 40px;
        height: 40px;
        background-size: contain;
        display: block;
        margin: 10% auto;
        mask-size: contain;
        z-index: 8;
    }
}

//Attachment classes to mixins
.--button, ._button {
    @include default-button;
}

._loading {
    @include loading;
}

@mixin search-input {
    position: relative;
    input {
        background-color: transparent;
        background-position: left .7rem center;
        background-repeat: no-repeat;
        color: color(base, Black);
        height: 3rem;
        padding-left: 3rem;
        &::placeholder {
            color: color(base, Black);
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: color(base, Black);
        }

        &::-ms-input-placeholder {
            color: color(base, Black);
        }
        @include breakpoint(medium) {
            @include transition;
            &:focus {
                border-color: color(gray, Gray-300);
            }
        }
    }
    button {
        cursor: pointer;
        left: .8rem;
        width: 2.8rem;
        height: 2.8rem;
        position: absolute;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='17px' height='16px' viewBox='0 0 17 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-15' transform='translate(-6.000000, -6.000000)' stroke='#{color(base, Black)}'%3E%3Cg id='Group-14' transform='translate(7.335900, 6.724200)'%3E%3Cpath d='M12.425,6.211 L12.425,6.211 C12.426,9.642 9.645,12.424 6.214,12.4250003 C2.783,12.426 0.001,9.645 0,6.214 C0,2.783 2.78,0.001 6.211,0 C7.859,0 9.44,0.654 10.605,1.819 C11.772,2.982 12.426,4.562 12.426,6.209 L12.425,6.211 Z' id='Stroke-10' stroke-width='0.895'%3E%3C/path%3E%3Cline x1='10.6054' y1='10.6053' x2='14.3094' y2='14.3093' id='Stroke-12' stroke-width='1.79'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        @include breakpoint(medium) {
            @include transition;
            &:hover {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='17px' height='16px' viewBox='0 0 17 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-15' transform='translate(-6.000000, -6.000000)' stroke='#{color(base, Black)}'%3E%3Cg id='Group-14' transform='translate(7.335900, 6.724200)'%3E%3Cpath d='M12.425,6.211 L12.425,6.211 C12.426,9.642 9.645,12.424 6.214,12.4250003 C2.783,12.426 0.001,9.645 0,6.214 C0,2.783 2.78,0.001 6.211,0 C7.859,0 9.44,0.654 10.605,1.819 C11.772,2.982 12.426,4.562 12.426,6.209 L12.425,6.211 Z' id='Stroke-10' stroke-width='0.895'%3E%3C/path%3E%3Cline x1='10.6054' y1='10.6053' x2='14.3094' y2='14.3093' id='Stroke-12' stroke-width='1.79'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
}

@mixin slider {
    .catalog__category-list {

        ul {
            padding: 2rem 0 0;
            position: relative;
            .swiper-container {
                margin: 0;
                width: 100%;

                .swiper-slide {
                    width: calc(33.3334% - 10px);
                    @include breakpoint(large) {
                        width: calc(25% - 20px);
                    }
                    @include breakpoint(small only) {
                        width: calc(50% - 10px);
                    }
                }
            }
            &:before,&:after {
                transition-duration: 400ms;
                transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
                content: "";
                opacity: 0;
                transition-property: opacity;
                position: absolute;
                height: 100%;
                width: 300px;
                top: 0;
                pointer-events: none;
            }
            &:before {
                left: 0;
                z-index: 2;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.47) 33%, rgba(255, 255, 255, 0.79) 56%, rgba(255, 255, 255, 0.95) 80%, #ffffff 100%);
            }
            &:after {
                right: 0;
                z-index: 2;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.47) 33%, rgba(255, 255, 255, 0.79) 56%, rgba(255, 255, 255, 0.95) 80%, #ffffff 100%);
            }
        }

        .slider__controls {
            position: absolute;
            right: 0;
            top: 1.5rem;
            @include breakpoint(small only) {
                display: none;
            }

            .swiper-button-next {
                right: 4.5rem;
                z-index: 1;

                &:after {
                    font-size: 1.6rem;
                    font-weight: 900;
                    color: color(gray, Gray-200);
                }
            }

            .swiper-button-prev {
                right: 9rem;
                left: auto;
                z-index: 1;
                @include breakpoint(small only) {
                    right: 4rem;
                }

                &:after {
                    font-size: 1.6rem;
                    font-weight: 900;
                    color: color(gray, Gray-200);
                }
            }
        }
    }
}
