.cms-page {
    &.error-page {
        padding: 1rem 0 0;
        .breadcrumbs {
            display: none;
        }
    }
    .content {
        h1 {
            font-size: 2.4rem;
            font-weight: 600;
            line-height: 3rem;
            margin: 0 0 2rem;
            @include breakpoint(small only) {
                margin: 2rem 0;
            }
        }
        h2 {
            font-size: 2.6rem;
            line-height: 3.2rem;
            font-weight: 600;
            margin: 0 0 2rem;
        }
        h3 {
            font-size: 2.2rem;
            line-height: 2.8rem;
            font-weight: 600;
            margin: 0 0 2rem;
        }
        h4 {
            font-size: 1.8rem;
            line-height: 2.4rem;
            font-weight: 600;
            margin: 0 0 2rem;
        }
        p {
            font-size: 1.5rem;
            line-height: 2.3rem;
            margin: 0 0 2rem;
        }
        a:not(._button) {
            color: color(base, Black);
            text-decoration: underline;
            font-weight: 600;
            &:hover {
                color: darken(color(base, Green), 5%);
            }
        }
        ul, ol {
            list-style: disc inside;
            margin: 0 0 2rem;
            font-size: 1.5rem;
            line-height: 2.3rem;
        }
        ol {
            list-style: decimal inside;
        }
        table {
            width: 100%;
            margin: 0 0 2rem;
            background: color(base, White);
            tr {
                &:nth-child(even) {
                    background: #FAFAFA;
                }
                th {
                    font-weight: 500;
                }
                th, td {
                    text-align: left;
                    border: .1rem solid color(gray, Gray-200);
                    border-collapse: collapse;
                    padding: .4rem .6rem;
                }
            }
        }
        .scroll-to {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .cms__menu {
        max-width: 90%;
        @include breakpoint(small only) {
            max-width: 100%;
            margin: 1rem 0 0;
            display: none;
        }
        ul {
            li {
                a {
                    display: block;
                    font-size: 1.4rem;
                    strong {
                        display: inline;
                    }
                    color: #4A4A4A;
                    padding: .8rem 0;
                    border-bottom: .1rem solid #f4f4f4;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &.title {
                    display: flex;
                    align-items: center;
                    position: relative;
                    font-size: 1.4rem;
                    cursor: pointer;
                    line-height: 4rem;
                    padding: 0 1.8rem 0 0;
                    border-bottom: 1px solid #f4f4f4;
                    text-transform: none;
                    font-weight: 600;
                }
            }
        }
    }
    div.small-24.medium-8.cell {
        @include breakpoint(small only) {
            margin: 0 0 2rem;
        }
    }
    .cms-accordion {
        ul {
            margin: 1rem 0;
            p {
                margin: 0;
            }
        }
        .question {
            transition: all 0.5s;
            margin: 0 0 1rem;
            list-style: none;
            font-weight: 600;
            cursor: pointer;
        }
        .question-text {
            transition: all 0.5s;
        }
    }
    .klantenservice {
        img {
            margin: 1rem auto;
        }
        h3 {
            margin: 0 0 1rem;
        }
        p {
            font-size: 1.4rem;
        }
    }
    .page-block {
        background: color(gray, Gray-200);
        padding: 2rem;
        margin: 0 0 2rem;
        a {
            line-height: 3rem;
            font-weight: 600;
        }
    }
}
