.catalog__category-list {
    position: relative;
    > ul {
        li {
            a {
                @include transition;
                position: relative;
                display: block;
                margin-bottom: 1.5rem;
                border: 1px solid transparent;
                background: color(base, White);
                border-radius: 3px;
                @include breakpoint(small only) {
                    padding: 0.5rem;
                }
                &:hover {
                    text-decoration: none;
                    //box-shadow: 2px 2px 8px -5px rgba(0,00,0.4);
                    border: 1px solid color(gray, Gray-100);
                }
                .product__label {
                    @include head-font;
                    background: color(base, Green);
                    padding: 0 1rem;
                    color: color(base, White);
                    font-size: 1.3rem;
                    font-weight: 600;
                    top: 1rem;
                    left: 1rem;
                    position: absolute;
                    z-index: 2;
                    @include breakpoint(small only) {
                        line-height: 1.6rem;
                    }
                    @include breakpoint(medium only) {
                        line-height: 1.6rem;
                    }
                    &.sale {
                        background: color(base, CtaRed);
                    }
                }
                .image__holder {
                    display: block;
                    overflow: hidden;
                    img {
                        margin: auto;
                        width: 100%;
                        padding: 1rem;
                        &:hover {
                            transform: scale(1.1);
                            transition: transform 0.8s ease-out;
                        }
                    }
                }
                .product__holder {
                    margin: 1.5rem 0 1.5rem;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: start;
                    padding: 0 0.5rem;
                    .product__short-description {
                        font-size: 1.3rem;
                        line-height: 1.8rem;
                        max-height: 3.6rem;
                        -webkit-box-orient: vertical;
                        display: block;
                        display: -webkit-box;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        font-weight: 300;
                    }
                    .product__name {
                        display: flex;
                        align-items: start;
                        flex-direction: column;
                        width: 60%;
                        @include breakpoint(small only) {
                            width: 100%;
                        }

                        h2 {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            text-align: left;
                            color: color(base, Black);
                            font-weight: 500;
                            display: inline-block;
                            @include breakpoint(small only) {
                                font-size: 1.4rem;
                                max-height: 10rem;
                            }
                        }
                    }
                    .price__holder {
                        display: flex;
                        align-items: center;
                        width: 40%;
                        justify-content: flex-end;
                        gap: 0.5rem;
                        @include breakpoint(small only) {
                            width: 100%;
                            justify-content: flex-start;
                        }
                        .price__old-price {
                            font-size: 1.2rem;
                            line-height: 1.4rem;
                            text-decoration: line-through;
                            display: inline-block;
                            margin-right: 3px;
                            color: color(gray, Gray-200);
                            @include breakpoint(small only) {
                                font-size: 1.2rem;
                            }
                        }
                        .price {
                            display: inline-block;
                            font-size: 1.4rem;
                            line-height: 1.4rem;
                            font-weight: 700;
                            color: color(base, Black);
                            @include breakpoint(small only) {
                                font-size: 1.3rem;
                                line-height: 1.8rem;
                            }
                        }
                    }
                    ._stock {
                        display: inline-block;
                        color: color(base, Green);
                        line-height: 2.3rem;
                        font-size: 1.2rem;
                        font-weight: 500;
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='15px' viewBox='0 0 512 512' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E001-tick-mark%3C/title%3E%3Cdefs%3E%3Cfilter id='filter-1'%3E%3CfeColorMatrix in='SourceGraphic' type='matrix' values='0 0 0 0 0.000000 0 0 0 0 0.709804 0 0 0 0 0.000000 0 0 0 1.000000 0'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg filter='url(%23filter-1)' id='001-tick-mark'%3E%3Cg%3E%3Cpath d='M256,0 C114.837333,0 0,114.837333 0,256 C0,397.162667 114.837333,512 256,512 C397.162667,512 512,397.162667 512,256 C512,114.837333 397.162667,0 256,0 Z M385.749333,201.749333 L247.082667,340.416 C242.922667,344.576 237.461333,346.666667 232,346.666667 C226.538667,346.666667 221.077333,344.576 216.917333,340.416 L147.584,271.082667 C139.242667,262.741333 139.242667,249.258667 147.584,240.917333 C155.925333,232.576 169.408,232.576 177.749333,240.917333 L232,295.168 L355.584,171.584 C363.925333,163.242667 377.408,163.242667 385.749333,171.584 C394.090667,179.925333 394.090667,193.408 385.749333,201.749333 L385.749333,201.749333 Z' id='Shape' fill='%23000000' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        padding-left: 2rem;
                        background-position: left;
                    }
                    .--stock {
                        position: relative;
                        width: 100%;
                        font-weight: 500;
                        font-size: 1.2rem;
                        color: #008000FF;
                        margin-top: 1rem;

                        svg {
                            width: 1.5rem;
                            display: inline-block;
                            vertical-align: text-bottom;
                            stroke: #008000FF;
                        }
                    }
                }
            }
        }
    }
}

.home__product-list {
    .catalog__category-list > ul li a {
        margin-bottom: 0;
    }
}

.list-product-item {
    @include breakpoint(small only) {
        padding: 0 !important;
    }
    .price {
        font-size: 1.4rem;
    }
}

.list-product-item, .home-product-item {
    ._label{
        &.nieuw {
            span {
                background: color(base, Green);
                color: color(base, White);
                padding: .5rem 1rem;
                font-weight: 600;
                position: absolute;
                left: 0;
            }
        }
    }
}
