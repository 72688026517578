.breadcrumbs {
    margin: 2rem 0 1rem;
    ol {
        display: flex;
        @include breakpoint(small only) {
            flex-wrap: wrap;
        }
        li {
            display: flex;
            font-size: 1.3rem;
            line-height: 3rem;
            a, span {
                display: block;
                @include breakpoint(small only) {
                    font-size: 1.3rem;
                }
                ._home {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='15px' viewBox='0 0 50 49' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E001-house-black-silhouette-without-door%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='001-house-black-silhouette-without-door' fill='%23010002' fill-rule='nonzero'%3E%3Cpath d='M49.3371336,23.7449511 C50.2428339,22.7488599 50.1553746,21.2221498 49.1418567,20.3358306 L26.8127036,0.79771987 C25.7991857,-0.0885993485 24.1742671,-0.0700325733 23.1820847,0.839576547 L0.777035831,21.3775244 C-0.21514658,22.2871336 -0.263843648,23.8118893 0.669218241,24.781759 L1.23094463,25.3667752 C2.16286645,26.336645 3.66921824,26.4522801 4.59332248,25.6242671 L6.26775244,24.1247557 L6.26775244,46.0778502 C6.26775244,47.4247557 7.35912052,48.5151466 8.70504886,48.5151466 L17.4385993,48.5151466 C18.7845277,48.5151466 19.8758958,47.4247557 19.8758958,46.0778502 L19.8758958,30.719544 L31.0156352,30.719544 L31.0156352,46.0778502 C30.9962541,47.4237785 31.9592834,48.5141694 33.3052117,48.5141694 L42.5605863,48.5141694 C43.9065147,48.5141694 44.9978827,47.4237785 44.9978827,46.076873 L44.9978827,24.4338762 C44.9978827,24.4338762 45.4604235,24.8390879 46.0309446,25.3405537 C46.6004886,25.8410423 47.7967427,25.4397394 48.702443,24.442671 L49.3371336,23.7449511 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: top .6rem center;
                    width: 2rem;
                    height: 3rem;
                }
            }
            ._sep {
                margin: 0 .5rem;
            }
            > span span {
                display: block;
                font-weight: 600;
                color: color(base, Black);
            }
        }
    }
}
