.slider__controls {
    .slider__control {
        top: 50%;
        width: 22px;
        height: 38px;
        margin: -19px 0 0 0;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='23px' height='38px' viewBox='0 0 23 38' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(0.500000, 0.000000)'%3E%3Crect id='Rectangle' x='0' y='0' width='22' height='38'%3E%3C/rect%3E%3Cpolyline id='Path' stroke='#{color(gray, Gray-200)}' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' points='2 2 20 19 2 36'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        @include breakpoint(small only) {
            right: 5px;
        }
        &:hover {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='23px' height='38px' viewBox='0 0 23 38' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(0.500000, 0.000000)'%3E%3Crect id='Rectangle' x='0' y='0' width='22' height='38'%3E%3C/rect%3E%3Cpolyline id='Path' stroke='#{color(gray, Gray-200)}' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' points='2 2 20 19 2 36'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
        &.swiper-button-disabled {
            opacity: .5;
        }
        &.swiper-button-prev {
            z-index: 2;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='18px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E002-left-arrow%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='002-left-arrow' fill='#{color(base, Green)}' fill-rule='nonzero'%3E%3Cpath d='M3.57102439,9.00380488 L10.3047805,2.2697561 C10.4901951,2.08478049 10.5921951,1.83746341 10.5921951,1.5737561 C10.5921951,1.30990244 10.4901951,1.06273171 10.3047805,0.877463415 L9.71473171,0.287707317 C9.52960976,0.102146341 9.28214634,0 9.01843902,0 C8.75473171,0 8.50756098,0.102146341 8.32229268,0.287707317 L0.304682927,8.30517073 C0.118682927,8.49102439 0.0168292683,8.73936585 0.0175570549,9.00336585 C0.0168292683,9.26853659 0.118536585,9.51658537 0.304682927,9.70258537 L8.31482927,17.7122927 C8.50009756,17.8978537 8.74726829,18 9.01112195,18 C9.27482927,18 9.522,17.8978537 9.70741463,17.7122927 L10.2973171,17.1225366 C10.6811707,16.7386829 10.6811707,16.1138049 10.2973171,15.7300976 L3.57102439,9.00380488 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            position: absolute;
            background-repeat: no-repeat;
            left: 20px;
            height: 60px;
            width: 40px;
            background-size: 30px;
            background-position: center;
            top: 50%;
            transform: translateY(-50%);
            @include breakpoint(medium down) {
                left: 0;
                background-size: 20px;
            }
        }

        &.swiper-button-next {
            z-index: 2;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='18px' viewBox='0 0 11 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E002-left-arrow%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='002-left-arrow' transform='translate(5.304878, 9.000000) scale(-1, -1) translate(-5.304878, -9.000000) ' fill='#{color(base, Green)}' fill-rule='nonzero'%3E%3Cpath d='M3.57102439,9.00380488 L10.3047805,2.2697561 C10.4901951,2.08478049 10.5921951,1.83746341 10.5921951,1.5737561 C10.5921951,1.30990244 10.4901951,1.06273171 10.3047805,0.877463415 L9.71473171,0.287707317 C9.52960976,0.102146341 9.28214634,0 9.01843902,0 C8.75473171,0 8.50756098,0.102146341 8.32229268,0.287707317 L0.304682927,8.30517073 C0.118682927,8.49102439 0.0168292683,8.73936585 0.0175570549,9.00336585 C0.0168292683,9.26853659 0.118536585,9.51658537 0.304682927,9.70258537 L8.31482927,17.7122927 C8.50009756,17.8978537 8.74726829,18 9.01112195,18 C9.27482927,18 9.522,17.8978537 9.70741463,17.7122927 L10.2973171,17.1225366 C10.6811707,16.7386829 10.6811707,16.1138049 10.2973171,15.7300976 L3.57102439,9.00380488 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            position: absolute;
            background-repeat: no-repeat;
            height: 60px;
            width: 40px;
            background-size: 30px;
            background-position: center;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            @include breakpoint(medium down) {
                right: 0;
                background-size: 20px;
            }
        }
        &:after {
            display: none;
        }
    }
}

.new-products {
    @include slider;
}
