.catalog__product_media-gallery {
    .catalog__product_media-gallery_gallery {
        position: relative;
        @include breakpoint(small only) {
            margin: 0 0 2rem;
        }
        .product__label {
            @include head-font;
            background: color(base, Green);
            padding: 0 1.5rem;
            color: color(base, White);
            font-size: 1.3rem;
            font-weight: 600;
            top: 1rem;
            left: 1rem;
            position: absolute;
            z-index: 2;
            @include breakpoint(small only) {
                line-height: 1.6rem;
            }
            @include breakpoint(medium only) {
                line-height: 1.6rem;
            }
            &.sale {
                background: color(base, CtaRed);
            }
        }
        ._zoom {
            position: absolute;
            cursor: pointer;
            display: none;
            font-size: 1.2rem;
            font-style: italic;
            color: color(gray, Gray-200);
            height: 2.4rem;
            line-height: 2.4rem;
            background-size: 1.4rem;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='%23989898'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='10.5' y1='6.375' x2='10.5' y2='14.375' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            z-index: 3;
            bottom: 2rem;
            right: 2rem;
            padding-left: 1.8rem;
        }
        .swiper-pagination {
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .swiper-container {
            height: 100%;
            .swiper-slide {
                height: 500px;
                @include breakpoint(small only) {
                    height: calc(100% + 1rem);
                }
                &:hover {
                    ._zoom {
                        display: block;
                        @include breakpoint(small only) {
                            display: none;
                        }
                    }
                }
            }
            .swiper-pagination {
                .swiper-pagination-bullet {
                    width: 1rem;
                    height: 1rem;
                    border: .1rem solid color(gray, Gray-200);
                    background: transparent;
                    &.swiper-pagination-bullet-active {
                        background: color(gray, Gray-200);
                    }
                }
            }
        }
        ._holder {
            display: flex;
            align-items: center;
            justify-content: center;
            picture {
                width: 100%;
                height: auto;
                img {
                    aspect-ratio: 1/1;
                    width: 100%;
                    height: auto;
                    max-height: 500px;
                    object-fit: contain;
                }
            }
        }
        .slider__controls {
            .slider__control {
                top: 50%;
                transform: translateY(-50%);
                &.swiper-button-prev {
                    left: 10px;
                }
                &.swiper-button-next {
                    right: 0;
                }
            }
        }
    }
    .catalog__product_media-gallery_thumbnails {
        box-sizing: border-box;
        .swiper-container {
            height: 600px;
            padding: 0 0 15px;
            .swiper-slide {
                height: 98px;
            }
        }
        .thumb__holder {
            position: relative;
            display: block;
            border: 1px solid color(gray, Gray-200);
            background: color(base, White);
            cursor: pointer;
            margin: 0 10px 0 0;

            img {
                aspect-ratio: 1/1;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
            &._active, &:hover {
                border-color: color(base, Antracite);
                //content: '';
                //height: 3px;
                //position: absolute;
                //bottom: -10px;
                //left: 20%;
                //right: 20%;
                //background-color: color(base, Antracite);
            }
            ._zoom {
                display: block;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Casba' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Casba_Product_Desktop_V2' transform='translate(-247.000000, -664.000000)'%3E%3Cg id='Main' transform='translate(178.000000, 1.000000)'%3E%3Cg id='Top-left' transform='translate(51.000000, 0.000000)'%3E%3Cg id='Zoom' transform='translate(19.780937, 664.824818)'%3E%3Cpath d='M18.9099998,9.45546875 C18.9099998,14.6771701 14.6780916,18.9109375 9.45499992,18.9109375 C4.23355744,18.9109375 0,14.6771701 0,9.45546875 C0,4.23211806 4.23355744,0 9.45499992,0 C14.6780916,0 18.9099998,4.23211806 18.9099998,9.45546875 Z' id='Stroke-1' stroke='%23303030' stroke-width='2'%3E%3C/path%3E%3Cline x1='13.995625' y1='17.4740625' x2='22.6565625' y2='27.3001823' id='Stroke-3' stroke='%23303030' stroke-width='2'%3E%3C/line%3E%3Cline x1='9.7190625' y1='5.55018229' x2='9.7190625' y2='13.5501823' id='Line-6' stroke='%23989898' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='5.2190625' y1='9.67518229' x2='14.2190625' y2='9.67518229' id='Line-6' stroke='%23989898' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                height: auto;
                &:after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
            }
            .play__video {
                display: block;
                width: 50px;
                height: 50px;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='50px' height='50px' viewBox='0 0 50 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group'%3E%3Ccircle id='Oval' stroke='%23000000' stroke-width='2' cx='25' cy='25' r='24'%3E%3C/circle%3E%3Cpolygon id='Triangle' fill='%23000000' transform='translate(27.500000, 24.500000) rotate(-270.000000) translate(-27.500000, -24.500000) ' points='27.5 15 39 34 16 34'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                @include breakpoint(small only) {
                    width: 40px;
                    height: 40px;
                    background-size: contain;
                }
            }
        }
    }
}

.catalog__product_media-gallery_fullscreen {
    position: fixed;
    z-index: 8000;
    background: rgba(0,0,0,.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .catalog__product_media-gallery_fullscreen-container {
        background: color(base, White);
        box-shadow: 0 0 1.5rem 0 rgb(0 0 0 / 50%);
        border-radius: 5px;
        max-width: 90vw;
        max-height: 90vh;
        width: 70vw;
        height: 90vh;
        position: relative;
        @include breakpoint(small only) {
            width: 100vw;
            height: 100vh;
            max-height: 100vh;
            max-width: 100vw;
        }
    }
    .catalog__product-title {
        @include transition;
        position: absolute;
        display: inline;
        top: 2rem;
        left: 2rem;
        font-weight: 500;
        z-index: 9500;
        margin-right: 6rem;
        line-height: 2.6rem;
    }
    ._zoom {
        position: absolute;
        @include transition;
        cursor: pointer;
        display: block;
        width: 35px;
        height: 42px;
        background-size: 2rem;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='%23989898'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='10.5' y1='6.375' x2='10.5' y2='14.375' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        z-index: 3;
        bottom: 10%;
        right: 30%;
        @include breakpoint(small only) {
            bottom: 100px;
            right: 20px;
        }
        &:hover {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='#{color(base, Green)}'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='10.5' y1='6.375' x2='10.5' y2='14.375' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
        &._zoomed {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='%23989898'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            &:hover {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='31px' viewBox='0 0 26 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EZoom%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='03-Reclaimed_PDP_V4' transform='translate(-316.000000, -811.000000)' stroke='#{color(base, Green)}'%3E%3Cg id='Top-left' transform='translate(234.000000, 30.000000)'%3E%3Cg id='Zoom' transform='translate(83.000000, 782.000000)'%3E%3Cpath d='M19.6909373,10.2802865 C19.6909373,15.5019878 15.4590291,19.7357552 10.2359374,19.7357552 C5.01449494,19.7357552 0.7809375,15.5019878 0.7809375,10.2802865 C0.7809375,5.05693576 5.01449494,0.824817708 10.2359374,0.824817708 C15.4590291,0.824817708 19.6909373,5.05693576 19.6909373,10.2802865 Z' id='Stroke-1' stroke-width='2'%3E%3C/path%3E%3Cline x1='14.7765625' y1='18.2988802' x2='23.4375' y2='28.125' id='Stroke-3' stroke-width='2'%3E%3C/line%3E%3Cline x1='6' y1='10.5' x2='15' y2='10.5' id='Line-6' stroke-linecap='square'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
    ._close {
        @include transition;
        z-index: 77;
        cursor: pointer;
        position: absolute;
        width: 35px;
        height: 35px;
        top: 20px;
        right: 20px;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='02-Reclaimed_Categorie_-Filter_MOBILE-V4' transform='translate(-15.000000, -20.000000)'%3E%3Cg id='Mid' transform='translate(-2.000000, -2.000000)' fill='#{color(base, Black)}' font-family='ProximaNova-Regular, Proxima Nova' font-size='14' font-weight='normal' line-spacing='30'%3E%3Ctext id='Nieuw-Vintage-Prijs'%3E%3Ctspan x='17' y='13.0546875'%3E%3C/tspan%3E%3Ctspan x='17' y='63.0546875'%3ENieuw%3C/tspan%3E%3Ctspan x='17' y='113.054688'%3EVintage%3C/tspan%3E%3Ctspan x='17' y='163.054688'%3EPrijs%3C/tspan%3E%3Ctspan x='17' y='213.054688'%3ERingmaat%3C/tspan%3E%3Ctspan x='17' y='263.054688'%3ESlijpvorm%3C/tspan%3E%3C/text%3E%3C/g%3E%3Cg id='Top' transform='translate(-28.000000, 0.000000)' stroke='#{color(base, Black)}' stroke-width='2'%3E%3Cg id='X' transform='translate(44.000000, 21.000000)'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 2rem;
        &:hover {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EX%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='02-Reclaimed_Categorie_-Filter_MOBILE-V4' transform='translate(-15.000000, -20.000000)'%3E%3Cg id='Mid' transform='translate(-2.000000, -2.000000)' fill='#{color(gray, Gray-200)}' font-family='ProximaNova-Regular, Proxima Nova' font-size='14' font-weight='normal' line-spacing='30'%3E%3Ctext id='Nieuw-Vintage-Prijs'%3E%3Ctspan x='17' y='13.0546875'%3E%3C/tspan%3E%3Ctspan x='17' y='63.0546875'%3ENieuw%3C/tspan%3E%3Ctspan x='17' y='113.054688'%3EVintage%3C/tspan%3E%3Ctspan x='17' y='163.054688'%3EPrijs%3C/tspan%3E%3Ctspan x='17' y='213.054688'%3ERingmaat%3C/tspan%3E%3Ctspan x='17' y='263.054688'%3ESlijpvorm%3C/tspan%3E%3C/text%3E%3C/g%3E%3Cg id='Top' transform='translate(-28.000000, 0.000000)' stroke='#{color(gray, Gray-200)}' stroke-width='2'%3E%3Cg id='X' transform='translate(44.000000, 21.000000)'%3E%3Cline x1='5.35924893e-14' y1='-1.33981223e-14' x2='14' y2='14' id='Line'%3E%3C/line%3E%3Cpath d='M0,14 L14,0 L0,14 Z' id='Line'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
    }
    .catalog__product_media-gallery_gallery {
        width: 100%;
        height: 80%;
        position: relative;
        @include breakpoint(small only) {
            height: 80%;
            .swiper-container {
                height: 100%;
            }
        }
        ._holder {
            background: transparent;
            margin: 6rem 0 0;
            picture {
                width: auto;
                height: auto;
                img {
                    max-height: 70rem;
                    width: auto;
                }
            }
            @include breakpoint(small only) {
                picture {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .slider__controls {
            .--prev {
                left: 2rem;
                z-index:9000;
                position: absolute;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 2.4rem;
                cursor: pointer;
                @include breakpoint(small only) {
                    left: 1rem;
                }
            }
            .--next {
                right: 2rem;
                z-index: 9000;
                position: absolute;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 2.4rem;
                cursor: pointer;
                @include breakpoint(small only) {
                    right: 1rem;
                }
            }
        }
    }
    .catalog__product_media-gallery_thumbnails {
        position: absolute;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
        @include breakpoint(small only) {
            left: 2rem;
            right: 0;
            transform: initial;
            overflow: hidden;
        }
        .swiper-slide {
            width: 20%;
            @include breakpoint(large) {
                width: 70px;
            }
        }
        .thumb__holder {
            height: 6.5rem;
            width: 6.5rem;
        }
    }
}
