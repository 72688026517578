.search__autocomplete {
    position: relative;
    ._loading {
        width: 20px;
        height: 20px;
        right: 3rem;
        left: auto;
        top: 0.5rem;
        bottom: 0;
        background: color(base, White);
        @include breakpoint(small only) {
            width: 20px;
            height: 3rem;
            margin: 8px 0;
            right: 5rem;
            background: color(gray, Gray-100);
        }
        ._spinner {
            margin: 0;
            width: 20px;
            height: 20px;
        }
    }
    ._remove {
        @include transition;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        cursor: pointer;
        @include breakpoint(small only) {
            margin: 8px 0;
        }
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-200)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-200)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
        }
    }
    .search__autocomplete_dropdown {
        position: absolute;
        background: color(base, White);
        border-radius: 3px;
        border: 1px solid color(gray, Gray-300);
        left: -10%;
        right: -10%;
        top: 50px;
        z-index: 50;
        @include breakpoint(small only) {
            left: -3rem;
            right: -8rem;
            top: 75px;
        }
        ul {
            li {
                a {
                    display: flex;
                    padding: 5px 10px;
                    border-bottom: 1px solid color(gray, Gray-100);
                    justify-content: center;
                    @include breakpoint(small only) {
                        background: color(gray, Gray-100);
                        padding: 5px 30px;
                    }
                    &:hover {
                        text-decoration: none;
                        background: color(gray, Gray-100);
                    }
                    .image__holder {
                        display: block;
                        margin-right: 10px;
                    }
                    ._title {
                        flex: 1;
                        padding: 9px 0;
                        font-size: 14px;
                        line-height: 20px;
                        color: color(base, Black);
                        font-weight: normal;
                        @include breakpoint(small only) {
                            font-size: 1.4rem;
                            line-height: 16px;
                            margin-right: 20px;
                        }
                    }
                    .price__holder {
                        padding: 5px 0;
                        margin-left: auto;
                        .price__old-price {
                            font-size: 12px;
                            text-decoration: line-through;
                            display: block;
                            text-align: right;
                            color: color(gray, Gray-200);
                            @include breakpoint(small only) {
                                font-size: 10px;
                            }
                        }
                        .price {
                            text-align: right;
                            display: inline-block;
                            font-size: 14px;
                            line-height: 30px;
                            font-weight: 500;
                            color: color(base, Green);
                            @include breakpoint(small only) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
                &.more__results a {
                    border: none;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 20px;
                }
            }
        }
        .no__results {
            display: block;
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            line-height: 30px;
        }
    }
}
