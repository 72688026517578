.catalog__category-filter {
    @include breakpoint(small only) {
        .modal__header {
            position: relative;
            .close-button {
                width: 50px;
                height: 50px;
                //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, Black)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
                right: 0 !important;
                background-size: 20px;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute !important;
                span {
                    display: block;
                    width: 50px;
                    height: 50px;
                }
                &._back {
                    right: 0 !important;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(base, Black)}'%3E%3Cpath fill-rule='evenodd' d='M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                }
            }
        }
        .modal {
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
        }
        .modal__content {
            height: 100%;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            @include breakpoint(medium up) {
                padding: 0 10px;
            }
        }
        .modal__footer {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 6;
            height: 60px;
            background: color(base, White);
            padding: 10px;
            text-align: center;
            ._button {
                height: 40px;
                width: 100%;
                line-height: 40px;
                font-size: 13px;
                &:hover {
                    span {
                        color: color(base, Green);
                    }
                }
                span {
                    color: color(base, White);
                    font-weight: 500;
                    line-height: 40px;

                }
            }
        }
    }

    .modal__content {
        .title {
            font-size: 2.4rem;
            line-height: 3rem;
            font-family: "Work Sans", sans-serif;
            font-weight: 500;
            margin: 0 0 .5rem;
        }
    }

    dl {
        @include breakpoint(medium up) {
            margin: 0;
        }
        dt {
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            color: color(base, Black);
            line-height: 34px;
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;
            &:after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 5l7 7-7 7' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right center;
                position: absolute;
                width: 2rem;
                height: 2rem;
                top: 50%;
                right: 0;
                background-size: 2rem;
                transform: translateY(-50%);
                @include breakpoint(small only) {
                    background-image: none;
                }
            }
            @include breakpoint(small only) {
                padding: 0 20px;
                line-height: 44px;
                border-top: none;
                border-bottom: 1px solid color(gray, Gray-100);
                font-size: 14px;
                font-weight: 500;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");                background-repeat: no-repeat;
                background-size: 1.6rem;
                background-position: right 20px center;
                font-family: "Work Sans", sans-serif;
            }
        }
         ._visible {
             @include transition;
             @include breakpoint(small only) {
                 background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                 background-repeat: no-repeat;
             }
            &:after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right center;
                position: absolute;
                width: 2rem;
                height: 2rem;
                top: 50%;
                right: 0;
                background-size: 2rem;
                transform: translateY(-50%);
                @include breakpoint(small only) {
                    background-image: none;
                }
            }
        }

        dd {
            @include breakpoint(medium up) {
                margin: 10px 0;
            }
            @include breakpoint(small only) {
                transition: transform .15s linear;
                display: block;
                margin: 0;
                padding: 0;
                top: 0;
                z-index: 400;
                //position: fixed;
                bottom: 60px;
                background: color(base, White);
                width: 100%;
                min-height: auto;
                left: 100%;
                overflow: scroll;
            }

            ol {
                li {
                    @include breakpoint(small only) {
                        padding: 0 20px;
                        border-bottom: 1px solid color(gray, Gray-100);
                    }
                    label, a {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        line-height: 28px;
                        cursor: pointer;
                        color: color(base, Black);
                        @include breakpoint(small only) {
                            line-height: 49px;
                        }
                        @include breakpoint(between-medium-small only) {
                            font-size: 12px;
                        }
                        @include breakpoint(medium only) {
                            font-size: 12px;
                        }
                        &:hover {
                            ._checkbox {
                                border-color: color(gray, Gray-200);
                            }
                        }
                        ._title {
                            overflow: hidden;
                            white-space: nowrap;
                            flex: 1;
                            text-overflow: ellipsis;
                            font-size: 14px;
                            line-height: 24px;
                            @include breakpoint(small only) {
                                line-height: 44px;
                            }
                        }
                        ._checkbox {
                            border: 1px solid color(gray, Gray-200);
                            display: block;
                            width: 14px;
                            height: 14px;
                            border-radius: 2px;
                            margin-right: 8px;
                        }
                        ._count {
                            margin-left: auto;
                            color: color(gray, Gray-200);
                        }

                        &._active ._checkbox {
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='7px' viewBox='0 0 10 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EFill 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cpolygon id='Fill-1' stroke='%23231F20' fill='%23FFFFFF' points='3.54581617 5.53783002 1.6370223 3.34424853 1 4.0737009 3.54581617 7 9 0.732064558 8.36525075 0'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }

                &._swatch {
                    display: flex;
                    flex-wrap: wrap;
                    @include breakpoint(small only) {
                        padding: 20px 0;
                        margin: 0;
                    }

                    li {
                        width: 25%;
                        @include breakpoint(medium down) {
                            width: 33.33%;
                        }
                        @include breakpoint(small only) {
                            border-bottom: 0;
                        }

                        label {
                            display: block;
                            text-align: center;
                            padding: 3px 0;

                            ._color-container {
                                display: block;
                                text-align: center;

                                ._color {
                                    margin: 0 auto;
                                    display: block;
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    border: 1px solid transparent;

                                    &._white {
                                        border-color: color(gray, Gray-200);
                                    }

                                    &:hover {
                                        border-color: color(gray, Gray-200);
                                    }
                                }
                            }

                            ._title {
                                display: block;
                                font-size: 12px;
                            }

                            &._active ._color {
                                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='11px' viewBox='0 0 15 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='5.13132462 8.81921546 2.04945992 5.73735077 1 6.77942013 5.13132462 10.9107447 14 2.04206936 12.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23FFFFFF' fill='%23FFFFFF' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: center;

                                &._white {
                                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='11px' viewBox='0 0 15 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='5.13132462 8.81921546 2.04945992 5.73735077 1 6.77942013 5.13132462 10.9107447 14 2.04206936 12.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='White'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='#{color(base, Black)}' fill='#{color(base, Black)}' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                                }
                            }
                        }
                    }
                }

                &._columns {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        width: 50%;
                    }
                }
            }

            .filter__actions {
                @include breakpoint(small only) {
                    padding: 10px 15px;
                }
                a {
                    font-size: 12px;
                    color: color(base, Black);
                    font-weight: 600;
                }
            }
        }

        &.catalog__category-sub-categories {
            margin: 0 0 20px;
            dt {
                font-size: 16px;
                line-height: 19px;
            }

            dd {
                ol {
                    li {
                        a {
                            position: relative;
                            padding-left: 2rem;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                            background-size: 1.4rem;
                            background-repeat: no-repeat;
                            background-position: left center;
                            &:hover, &._active {
                                font-weight: 600;
                                text-decoration: none;
                            }
                            //&:hover {
                            //    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                            //    background-size: 1.6rem;
                            //    background-repeat: no-repeat;
                            //    background-position: left center;
                            //}
                        }
                    }
                }
            }
        }
        &._visible {

            dd {
                @include breakpoint(medium up) {
                    display: block;
                }
                //@include breakpoint(small only) {
                //    transform: translateX(-100%);
                //}
            }
        }
    }
    .price__filter {
        @include breakpoint(small only) {
            padding: 0 2rem;
        }
        ._error {
            font-size: 1.3rem;
        }
        .custom__controls {
            display:flex;
            justify-content: space-between;
            margin: 15px 0 10px;
            input[type=number] {
                width: 33.34%;
                @include breakpoint(medium only) {
                    padding: 0 10px;
                }
            }
        }
        .vue-slider {
            display: flex;
            justify-content:center;
            padding: 12px 3px !important;
            .vue-slider-rail {
                width: 90%;
                @include breakpoint(medium only) {
                    width: 80%;
                }
                @include breakpoint(between-medium-small only) {
                    width: 80%;
                }
                .vue-slider-process {
                    background: color(base, Antracite);
                }
                .vue-slider-dot-handle {
                    width: 22px;
                    height: 22px;
                    box-shadow: none;
                    border-radius: 50%;
                    border: 2px solid color(base, Antracite);
                }
            }
        }
    }

}

.catalog__category-filter-placeholder {
    span {
        display: block;
        background: color(base, Black);
        height: 200px;
        margin: 0 0 20px;
    }
}

.catalog__categories {
    h3 {
        font-family: Work Sans,sans-serif;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 3rem;
    }
}
