.checkout-cart {
    .cart__header {
        display: flex;
        align-items: center;
        gap: 3rem;
        @include breakpoint(medium) {
            margin: 2rem 0;
            justify-content: flex-end;
            h1 {
                margin-right: auto;
            }
        }
        @include breakpoint(small only) {
            p, button {
                display: none;
            }
        }
        h1 {
            @include head-font-style;
            margin-right: auto;
        }
    }
    .cart__container {
        margin: 1rem 0 0;
        @include breakpoint(medium) {
            margin: 1rem 0 4rem;
        }
        .--heading {
            span {
                display: block;
                //@include secondary-font;
                line-height: 4rem;
                font-size: 1.6rem;
                font-weight: 700;
                &.--right {
                    text-align: right;
                }
            }
        }
        .image__holder {
            display: inline-flex;
            padding: .5rem;
            aspect-ratio: 1;
            @include breakpoint(small only) {
                width: 7rem;
                height: 7rem;
            }
        }
        .item__details {
            padding: 1rem 0;
            > a {
                font-size: 1.4rem;
                line-height: 2.4rem;
                font-weight: 700;
                color: color(base, Black);
                @include breakpoint(medium) {
                    font-size: 1.6rem;
                }
            }
            span {
                display: flex;
                font-size: 1.2rem;
                line-height: 2rem;
                @include breakpoint(medium) {
                    font-size: 1.3rem;
                }
            }
            .--promotion {
                color: color(base, Black);
                font-weight: 700;
            }
        }
        .item__actions {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            padding: 1rem 0;
            @include breakpoint(small only) {
                flex-flow: row-reverse;
            }
            @include breakpoint(medium) {
                select, input {
                    order: 1;
                }
                button {
                    order: 2;
                }
            }
            form {
                select {
                    height: 4rem;
                    line-height: 4rem;
                    padding: 0 1.5rem;
                }
            }
            .--remove {
                width: 1.8rem;
                height: 1.8rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-200)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19 7-.867 12.142A2 2 0 0 1 16.138 21H7.862a2 2 0 0 1-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3M4 7h16'/%3E%3C/svg%3E");
                @include breakpoint(medium) {
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-200)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19 7-.867 12.142A2 2 0 0 1 16.138 21H7.862a2 2 0 0 1-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3M4 7h16'/%3E%3C/svg%3E");
                    }
                }
            }
            select, input {
                width: 7.6rem;
                &[disabled] {
                    background: color(gray, Gray-100);
                    cursor: not-allowed;
                }
            }
        }
        .price__holder {
            padding: 1.5rem 0;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: .5rem;
            @include breakpoint(medium) {
                padding: 1rem 0;
            }
            span {
                display: block;
                font-size: 1.4rem;
                line-height: 1.6rem;
                @include breakpoint(medium) {
                    font-size: 1.6rem;
                    line-height: 3rem;
                }
                &.price__old-price {
                    color: color(gray, Gray-100);
                    font-size: 1.6rem;
                    text-decoration: line-through;
                }
            }
        }
        .cart__seperator {
            display: block;
            height: .1rem;
            background: color(gray, Gray-100);
        }
    }
    .cart__totals {
        border: .2rem solid color(base, Black);
        padding: 1.5rem 2rem;
        @include breakpoint(medium) {
            padding: 2rem 3rem;
        }
        .--shipping {
            margin: 0 0 1rem;
            span:first-of-type {
                font-weight: 600;
                font-size: 1.4rem;
                flex: 1;
            }
            span:last-of-type {
                font-size: 1.4rem;
                line-height: 2rem;
                text-align: right;
                flex: 1;
            }
        }
        div {
            display: flex;
            font-size: 1.4rem;
            @include breakpoint(medium) {
                font-size: 1.6rem;
            }
            &.--total {
                span {
                    font-size: 1.8rem;
                    @include breakpoint(medium) {
                        font-size: 2rem;
                    }
                    &:first-child {
                        font-weight: 700;
                    }
                }
            }
            span {
                display: block;
                line-height: 3.2rem;
                &:last-child {
                    margin-left: auto;
                }
                &.--free {
                    font-weight: 700;
                    color: color(alert, Success);
                }
            }
            &.shipping__message {
                display: block;
                margin: .5rem 0 0;
                border-radius: .3rem;
                background: color(gray, Gray-100);
                border: .1rem solid color(gray, Gray-100);
                font-size: 1.2rem;
                line-height: 1.8rem;
                padding: .5rem 1rem;

            }
        }
        p {
            margin: 2rem 0 0;
            font-size: 1.2rem;
            line-height: 1.6rem;
            a {
                text-decoration: underline;
                font-size: 1.2rem;
                line-height: 1.6rem;
            }
        }
        .--actions {
            display: flex;
            flex-flow: column;
            margin: 2rem 0 0;
            gap: 1rem;
            @include breakpoint(medium) {
                flex-flow: wrap;
                align-items: center;
                button {
                    margin-left: auto;
                    line-height: 4rem;
                    height: 4rem;
                    font-size: 1.6rem;
                    padding: 0 5rem;
                }
                p {
                    margin: 1rem 0 0;
                }
            }
            > a {
                color: color(base, Antracite);
                line-height: 3.2rem;
                font-size: 1.3rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, Antracite)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 19l-7-7 7-7' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: top .8rem left;
                background-size: 1.4rem;
                padding: 0 0 0 1.8rem;
            }
            p {
                font-size: 1.2rem;
                max-width: 40rem;
                line-height: 1.6rem;
                a {
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    text-decoration: underline;
                }
            }
        }
    }
    .usp__list {
        margin: 2rem 0 0;
        ul li {
            line-height: 2.2rem;
            font-size: 1.2rem;
            @include breakpoint(medium) {
                line-height: 2.6rem;
                font-size: 1.4rem;
            }
            &.title {
                font-size: 1.4rem;
                @include breakpoint(medium) {
                    line-height: 2.6rem;
                    font-size: 1.6rem;
                }
            }
        }
    }
    .checkout-clear-cart {
        a {
            @include default-button;
            background-color: color(gray, Gray-100);
            border-color: color(gray, Gray-100);
            &:hover {
                background-color: color(gray, Gray-100);
                border-color: color(gray, Gray-100);
            }
        }
    }
}

.checkout-cart-empty {
    .global__content {
        margin: 2rem 0 0;
        h1 {
            @include head-font-style;
        }
        a {
            margin: 2rem 0 0;
        }
    }
    .button {
        @include default-button;
        color: color(base, Black);
        @include breakpoint(medium) {
            &:hover {
                color: color(base, Black);
            }
        }
        &.secondary {
            background-color: color(gray, Gray-100);
            border-color: color(gray, Gray-200);
            @include breakpoint(medium) {
                &:hover {
                    background-color: color(gray, Gray-100);
                    border-color: color(gray, Gray-100);
                }
            }
        }
    }
    .sidebar_right {
        border: .1rem solid color(gray, Gray-100);
        padding: 1rem;
        margin: 2rem 0 0;
        a {
            font-size: 1.4rem;
            line-height: 2.1rem;
        }
        li {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, Green)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 1.6rem;
            padding-left: 2rem;
            background-position: left center;
        }
    }
}
