.checkout-success-page {
    h1 {
        font-size: 3rem;
        line-height: 3.4rem;
        font-weight: 600;
        margin: 0 0 1rem;
    }
    .order__details {
        padding: 2rem;
        h2 {
            padding: 2rem 0 1rem 0;
        }
        &__heading {
            font-size: 1.6rem;
            line-height: 3rem;
            border-bottom: .2rem solid color(gray, Gray-100);
            padding: 0 2rem;
            > div:last-child {
                text-align: right;
            }
        }
        &__item {
            font-size: 1.4rem;
            line-height: 3.8rem;
            border-top: .1rem solid color(gray, Gray-100);
            padding: 0 2rem;
            > div:last-child {
                text-align: right;
            }
        }
        &__totals {
            font-size: 1.6rem;
            line-height: 2.4rem;
            border-top: .3rem solid color(gray, Gray-100);
            padding: 2rem;
            ul {
                li {
                    line-height: 3.2rem;
                    > span {
                        vertical-align: unset;
                    }
                    > small {
                        font-size: 1rem;
                    }
                }
            }
            > div:last-child {
                text-align: right;
            }
        }
    }
    .order__address {
        background: color(gray, Gray-100);
        padding: 2rem;
        h4 {
            font-size: 1.3rem;
            line-height: 2.1rem;
            margin: 0 0 0.5rem;
            font-weight: 600;
        }
    }
}
