.contact-form {
    .breadcrumbs {
        display: none;
    }
    .contact__container {
        margin: 6rem 0 0;
        background: color(gray, Gray-400);
        padding: 5rem 10rem;
        @include breakpoint(small only) {
            padding: 2rem;
        }
        @include breakpoint(medium only) {
            padding: 2rem;
        }
        h1 {
            font-size: 2.4rem;
            font-weight: 600;
            margin: 0 0 1rem;
        }
        .contact__block {
            margin: 0 0 8rem;
        }
        .contact-form_box {
            form {
                .form__field-container {
                    display: flex;
                    @include breakpoint(small only) {
                        flex-wrap: wrap;
                    }
                    .form__field-row {
                        flex: 1;
                        @include breakpoint(small only) {
                            flex: 100%;
                        }
                        &:nth-of-type(1) {
                            margin: 0 4rem 0 0;
                            @include breakpoint(small only) {
                                margin: 0;
                            }
                        }
                        &:nth-of-type(2) {
                            margin: 0 0 0 4rem;
                            @include breakpoint(small only) {
                                margin: 0;
                            }
                        }
                        .field__container {
                            input[type='text'], input[type='tel'], input[type='email'], select {
                                line-height: 4rem;
                                height: 4rem;
                                border: .1rem solid color(gray, Gray-400);
                            }
                            select {
                                padding: 0 3rem 0 1rem;
                            }

                            textarea {
                                border: .1rem solid color(gray, Gray-400);
                                padding: 1rem;
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .contact__container-bottom {
        margin: 8rem 0 0;
        .contact__footer {
            .category__description {
                &._first {
                    p {
                        margin: 5rem 0 0;
                    }
                }
                &._second {
                    p {
                        margin: 0 0 5rem;
                    }
                }
            }
        }
    }
}
