@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/WorkSans-Light.woff2') format('woff2'),
    url('../assets/fonts/WorkSans-Light.woff') format('woff'),
    url('../assets/fonts/WorkSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/WorkSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/WorkSans-Regular.woff') format('woff'),
    url('../assets/fonts/WorkSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/WorkSans-Medium.woff2') format('woff2'),
    url('../assets/fonts/WorkSans-Medium.woff') format('woff'),
    url('../assets/fonts/WorkSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/WorkSans-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/WorkSans-SemiBold.woff') format('woff'),
    url('../assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/WorkSans-Bold.woff2') format('woff2'),
    url('../assets/fonts/WorkSans-Bold.woff') format('woff'),
    url('../assets/fonts/WorkSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'STIXGeneral';
    src: url('../assets/fonts/STIXGeneral-Italic.woff2') format('woff2'),
    url('../assets/fonts/STIXGeneral-Italic.woff') format('woff'),
    url('../assets/fonts/STIXGeneral-Italic.otf') format('otf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
