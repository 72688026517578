.folders {
    margin: 4rem 0 0;

    h1 {
        font-size: 3.2rem;
        line-height: 3.6rem;
        font-weight: 600;
        margin: 0 0 1rem;
        @include breakpoint(small only) {
            font-size: 2.4rem;
            line-height: 3rem;
        }
    }

    .folder__container {
        margin: 1rem 0;

        h2 {
            font-size: 2.4rem;
            line-height: 3rem;

            span {
                font-family: "STIXGeneral", sans-serif;
                font-size: 2.4rem;
                line-height: 3rem;

            }
        }
        p {
            margin: 0 0 2rem;
        }
        .folder__container--first {
            padding: 2rem;
            border: .1rem solid color(gray, Gray-300);
            margin: 0 0 2rem;
        }
        .folder__container--second {
            padding: 2rem;
            border: .1rem solid color(gray, Gray-300);
        }

        .new-products {
            margin: 6rem 0 0;
        }
    }
}
