.checkout-checkout_body {
    .breadcrumbs {
        display: none;
    }
    .header-container {
        border-bottom: .1rem solid color(gray, Gray-100);
        @include breakpoint(medium up) {
            padding: 2rem 0;
        }
        > .grid-container {
            //max-width: 72.6rem;
            border-bottom: none;
            @include breakpoint(small only) {
                padding-bottom: 1rem;
            }
            h1 {
                @include head-font-style;
                text-align: center;
                margin: 0;
            }
            .align-center {
                align-items: center;
            }
        }
        .header__logo {
            display: flex;
            width: 15rem;
            height: 100%;
            align-items: center;
            @include breakpoint(small only){
                width: 12rem;
            }
        }
        .flex {
            justify-content: flex-end;
            p.--secure {
                font-size: 1.3rem;
                background-position: left;
                background-size: 1.4rem;
                padding-left: 2rem;
            }
        }
    }
    .__usp.header {
        text-align: center;
        margin: 1rem 0;
        li {
            display: inline-flex;
            margin: 0 1rem;
            @include breakpoint(small only) {
                &:not(:nth-child(1)) {
                    display: none;
                }
            }
            @include breakpoint(medium) {
                &:not(:nth-child(1)):not(:nth-child(2)) {
                    display: none;
                }
            }
        }
    }
    .checkout-checkout {
        margin: 2rem 0 0;
        > .grid-container {
            //max-width: 72.6rem;
        }
        .checkout-container {
            .checkout-error-container {
                span {
                    font-size: 1.4rem;
                    margin: 0 0 1rem;
                    display: block;
                    a {
                        text-decoration: underline;
                        font-size: 1.4rem;
                    }
                }
                .error {
                    color: color(alert, Error);
                }
                .warning {
                    color: color(alert, Warning);
                }
            }
            .progress-bar {
                display: flex;
                margin: 0 0 1rem;
                @include breakpoint(medium) {
                    margin: 0 0 2rem;
                }
                li {
                    flex: 1;
                    position: relative;
                    &:not(:last-child) {
                        padding-right: 1rem;
                    }
                    &:not(:first-child)::before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 1.6rem 0 1.6rem 1rem;
                        border-color: transparent transparent transparent color(base, White);
                        @include breakpoint(medium) {
                            border-width: 2rem 0 2rem 1rem;
                        }
                    }
                    &:not(:last-child)::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 1.6rem 0 1.6rem 1rem;
                        border-color: transparent transparent transparent color(gray, Gray-100);
                        @include breakpoint(medium) {
                            border-width: 2rem 0 2rem 1rem;
                        }
                    }
                    span {
                        background: color(gray, Gray-100);
                        display: block;
                        line-height: 3.2rem;
                        font-size: 1.3rem;
                        text-align: center;
                        padding-left: .5rem;
                        @include breakpoint(medium) {
                            line-height: 4rem;
                            font-size: 1.5rem;
                            border-bottom: .2rem solid color(gray, Gray-200);
                            cursor: pointer;
                        }
                    }
                    &.--active, &.--complete {
                        &::after {
                            border-color: transparent transparent transparent color(base, Green);
                        }
                        span {
                            background: color(base, Green);
                            color: color(base, White);
                            @include breakpoint(medium) {
                                border-color: color(base, Green);
                                cursor: auto;
                            }
                        }
                    }
                }
            }
            .checkout-step-container {
                h2 {
                    @include head-font-style;
                    font-weight: 700;
                    line-height: 3rem;
                    font-size: 2rem;
                }
                .--loading {
                    position: static;
                    width: 100%;
                    height: 2rem;
                    display: block;
                    &.--margin {
                        margin: 2rem 0;
                    }
                }
                .billing__address {
                    margin: 2rem 0 0;
                }
                .shipping__address, .billing__address {
                    @include breakpoint(medium) {
                        padding: 2rem;
                        border: .1rem solid color(gray, Gray-100);
                    }
                    .shipping-address-select {
                        width: 100%;
                        select {
                            height: 4rem;
                            line-height: 4rem;
                        }
                        label {
                            display: block;
                            @include primary-font-size;
                            margin: 2rem 0 1rem;
                        }
                        @include breakpoint(medium) {
                            width: 50%;
                        }
                    }
                    .checkout__login {
                        p {
                            @include primary-font-size;
                            span {
                                text-decoration: underline;
                                cursor: pointer;
                                @include primary-font-size;
                            }
                        }
                    }
                    .checkout__login-container {
                        .--actions {
                            display: flex;
                            margin: 1rem 0 0;
                            gap: 2rem;
                            align-items: center;
                            a {
                                @include primary-font-size;
                                @include breakpoint(medium) {
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                    .field__group {
                        display: flex;
                        gap: 1rem;
                        margin: .5rem 0 1rem;
                        &.email__group {
                            flex-direction: column;
                        }
                        &.--newsletter {
                            margin: 1rem 0;
                            label {
                                @include primary-font-size;
                                font-weight: 500;
                                &::before {
                                    border: .2rem solid color(base, Black);
                                    height: 2rem;
                                    width: 2rem;
                                }
                                &::after {
                                    height: 1.2rem;
                                    left: .4rem;
                                    width: 1.2rem;
                                }
                            }
                        }
                        .checkout__field {
                            flex: 1;
                            label {
                                @include primary-font-size;
                                margin: 0 0 1rem;
                                font-weight: 600;
                                &.flex {
                                    align-items: center;
                                    gap: .5rem;
                                }
                            }
                            input, select {
                                width: 100%;
                                padding: 0 1rem;
                                @include primary-font-size;
                                height: 4rem;
                                line-height: 4rem;
                            }
                            input {
                                &.passed {
                                    border-color: color(alert, Success);
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(alert, Success)}'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm3.707-9.293a1 1 0 0 0-1.414-1.414L9 10.586 7.707 9.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4z' clip-rule='evenodd'/%3E%3C/svg%3E");
                                    background-repeat: no-repeat;
                                    background-size: 1.6rem;
                                    background-position: right 0.5rem center;
                                }
                            }
                            @include breakpoint(medium) {
                                &.email, &.password, &.street_0, &.city {
                                    max-width: 32rem;
                                }
                                &.firstname, &.lastname {
                                    max-width: 30%;
                                }
                                &.country, &.company {
                                    max-width: 25rem;
                                }
                                &.postcode, &.telephone {
                                    max-width: 19.2rem;
                                }
                                &.street_1, &.street_2 {
                                    max-width: 11rem;
                                }
                            }
                        }
                    }
                    .checkout__login__link {
                        @include primary-font-size;
                        @include breakpoint(medium) {
                            flex: 1;
                        }
                        span {
                            @include primary-font-size;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                    .billing-same-as-shipping-control {
                        margin: 2rem 0 0;
                        @include breakpoint(medium) {
                            border-top: .1rem solid color(gray, Gray-100);
                            padding: 2rem 0 0;
                            display: flex;
                            align-items: inherit;
                            gap: 1rem;

                        }
                        label {
                            display: inline-flex;
                        }
                        label, .guest__toggle {
                            @include primary-font-size;
                            span {
                                @include primary-font-size;
                                cursor: pointer;
                            }
                        }
                        .guest__toggle span {
                            text-decoration: underline;
                        }
                    }
                    .shipping-address-form,.billing-address-form {
                        .checkout__address-autocomplete {
                            @include breakpoint(medium) {
                                .field__groups {
                                    display: flex;
                                    gap: 1rem;
                                    .field__group {
                                        display: flex;
                                        gap: 1rem;
                                        margin: 0.5rem 0 0;
                                    }
                                }
                            }
                        }
                        .checkout__address-autocomplete_result {
                            .resolved__address {
                                @include primary-font-size;
                                padding: 1rem;
                                background: color(gray, Gray-100);
                                margin: 2rem 0;
                                width: 50%;
                                @include breakpoint(small only) {
                                    width: 100%;
                                }
                                address {
                                    font-style: italic;
                                    margin: 1rem 0;
                                }
                                span {
                                    @include primary-font-size;
                                }
                            }
                            > label {
                                @include primary-font-size;
                                margin: 1rem 0 0;
                                padding: 1rem;
                                background: color(gray, Gray-100);
                                text-decoration: underline;
                            }
                            label {
                                text-decoration: underline;
                                cursor: pointer;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            .shipping__methods, .payment__methods {
                @include breakpoint(medium) {
                    padding: 2rem;
                    border: .1rem solid color(gray, Gray-100);
                }
                .--loading-methods {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    @include primary-font-size;
                    margin: 2rem 0 0;
                    .--loading {
                        width: 2rem;
                    }
                }
                .shipping__method, .payment__method {
                    margin: .5rem 0;
                    display: flex;
                    gap: 2rem;
                    align-items: center;
                    @include breakpoint(medium) {
                        margin: 1rem 0;
                        padding: 1rem;
                        background: color(gray, Gray-100);
                        border: 0.3rem;
                    }
                    label {
                        gap: 1.5rem;
                        &::before {
                            width: 1.5rem;
                            height: 1.5rem;
                            border: .1rem solid color(gray, Gray-100);
                            @include breakpoint(medium) {
                                width: 2rem;
                                height: 2rem;
                            }
                        }
                        img {
                            @include breakpoint(small only) {
                                display: none;
                            }
                            object-fit: contain;
                        }
                        .--content {
                            display: block;
                            span {
                                display: block;
                                &.--title {
                                    display: flex;
                                    gap: 1rem;
                                    line-height: 2.1rem;
                                    font-size: 1.6rem;
                                    font-weight: 600;
                                    margin: 0 0 .5rem;
                                    .--free {
                                        font-weight: 500;
                                        color: color(alert, Success);
                                        line-height: 2.1rem;
                                    }
                                }
                                &.--sub {
                                    line-height: 2rem;
                                    font-size: 1.3rem;
                                    margin: -.5rem 0 0;
                                }
                            }
                        }
                        small {
                            font-size: 1.2rem;
                            margin-left: auto;
                            color: #3ba102;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233ba102' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.--%3E%3Cpath d='M512 165.4c0 127.9-70.05 235.3-175.3 270.1-20.04 7.938-41.83 12.46-64.69 12.46-64.9 0-125.2-36.51-155.7-94.47-54.13 49.93-68.71 107-68.96 108.1C44.72 472.6 34.87 480 24.02 480c-1.844 0-3.727-.219-5.602-.656-12.89-3.098-20.84-16.08-17.75-28.96 9.598-39.5 90.47-226.4 335.3-226.4C344.8 224 352 216.8 352 208s-7.2-16-16-16c-107.4 0-185 34.6-239.71 75.6.193-10.82 1.242-21.84 3.535-33.05 13.47-65.81 66.04-119 131.4-134.2 28.33-6.562 55.68-6.013 80.93-.005 56 13.32 118.2-7.412 149.3-61.24 5.664-9.828 20.02-9.516 24.66.828C502.7 76.76 512 121.9 512 165.4z'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            padding-left: 2rem;
                        }
                    }
                    input[type="radio"]:checked + label {
                        &::before {
                            border: .4rem solid color(base, Black);
                            @include breakpoint(medium) {
                                border-width: .6rem;
                            }
                        }
                        &::after {
                            display: none;
                        }
                    }
                    .shipping__method__options {
                        &.--narrow {
                            margin-left: 3.5rem;
                            max-width: 40rem;
                            .loading__stores {
                                font-size: 1.2rem;
                                display: flex;
                                align-items: center;
                                gap: 1rem;
                                .--loading {
                                    position: static;
                                    width: 2rem;
                                    height: 2rem;
                                }
                            }
                        }
                        .pick-up__stores__result {
                            select {
                                margin: 1rem 0 0;
                                width: 100%;
                            }
                            .selected__store {
                                margin: 1rem 0 0;
                                font-size: 1.2rem;
                                line-height: 2rem;
                                strong {
                                    font-size: 1.3rem;
                                    display: block;
                                }
                                ul {
                                    margin: 1rem 0 0;
                                    li {
                                        display: flex;
                                        span {
                                            display: block;
                                            &:first-child {
                                                min-width: 9rem;
                                            }
                                        }
                                        &.--today {
                                            color: color(alert, Success);
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .payment__method__options {
                        background: color(gray, Gray-100);
                        @include breakpoint(medium) {
                            margin-left: 8rem;
                            width: 50%;
                        }
                        .payment__method__option-holder {
                            .payment__field {
                                &:not(:last-of-type) {
                                    margin: 0 0 1rem;
                                    > label {
                                        display: block;
                                        font-size: 1.2rem;
                                        margin: 0 0 1rem;
                                    }
                                    .field__container {
                                        label {
                                            font-size: 1.2rem;
                                        }
                                    }
                                }
                                input[name=termsCondition]:checked + label::after, input[name=termsCondition] + label:hover::after {
                                    left: .5rem;
                                }
                                input[type=date] {
                                    width: 60%;
                                    font-size: 1.3rem;
                                }
                                .--terms {
                                    font-size: 1.2rem;
                                    gap: 0;
                                    a {
                                        font-size: 1.2rem;
                                        text-decoration: underline;
                                        margin: 0 0 0 5px;
                                        display: block;
                                        line-height: normal;
                                    }
                                }
                            }
                            span {
                                display: block;
                                &:not(:last-child) {
                                    margin: 0 0 1.5rem;
                                }
                                label {
                                    gap: .5rem;
                                    font-size: 1.2rem;
                                }
                            }
                        }
                        select {
                            padding-right: 4rem;
                            font-size: 1.2rem;
                            @include breakpoint(small only) {
                                width: 100%;
                                padding: 0 1.2rem;
                            }
                        }
                    }
                }
                .payment__method {
                    flex-wrap: wrap;
                    label {
                        display: flex;
                        flex-basis: 60%;
                        align-items: center;
                        ._title {
                            line-height: 2.1rem;
                            font-weight: 600;
                        }
                        ._fee {
                            line-height: 2.1rem;
                            color: color(alert, Success);
                            font-weight: 500;
                        }
                    }
                }
                .field-error {
                    display: block;
                    font-size: 1.2rem;
                    margin: .5rem 0 0;
                    color: color(alert, Error);
                }
            }
            .coupon-code__container {
                margin: 2rem 0 0;
                @include breakpoint(small only) {
                    padding: 2rem 0 0;
                    border-top: .1rem solid color(gray, Gray-100);
                }
                .toggle-coupon {
                    display: flex;
                    align-items: inherit;
                    gap: 1rem;
                    margin-left: 2rem;
                    label {
                        line-height: 2rem;
                        font-size: 1.3rem;
                    }
                }
                .coupon-code__form {
                    margin: 1.5rem 0 0;
                    @include breakpoint(medium) {
                        max-width: 60%;
                    }
                    .form-holder {
                        display: flex;
                        gap: 2rem;
                        input {
                            flex: 1;
                            height: 3.2rem;
                            line-height: 3.2rem;
                            @include breakpoint(medium) {
                                height: 4rem;
                                line-height: 4rem;
                            }
                        }
                        button {
                            height: 3.2rem;
                            line-height: 3.2rem;
                            @include breakpoint(medium) {
                                height: 4rem;
                                line-height: 4rem;
                                &:hover {
                                    background: color(base, White);
                                    border-color: color(base, Green);
                                    color: color(base, Green);
                                }
                            }
                        }
                    }
                }
            }
            .checkout-summary {
                margin: 2rem 0 0;
                @include breakpoint(medium) {
                    padding: 2rem;
                    border: .1rem solid color(gray, Gray-100);
                }
                h4 {
                    font-size: 1.3rem;
                    line-height: 2rem;
                    font-weight: 700;
                    @include breakpoint(small only) {
                        &:last-of-type {
                            margin: 2rem 0 0;
                        }
                    }
                }
                .summary-block {
                    background: color(gray, Gray-100);
                    margin: 1rem 0;
                    .--loading {
                        display: flex;
                        height: 2rem;
                        margin: 2rem;
                        position: relative;
                        top: 2.5rem;
                    }
                    ul {
                        padding: 1rem 1.5rem;
                        li {
                            display: flex;
                            gap: 2rem;
                            align-items: center;
                            span {
                                @include primary-font-size;
                                display: block;
                                &:last-child {
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                    .cart__items {
                        border-bottom: .1rem solid color(gray, Gray-100);
                        li:not(:last-child) {
                            margin: 0 0 1rem;
                        }
                    }
                    .cart__totals {
                        li {
                            &:not(:last-child) {
                                margin: 0 0 1rem;
                            }
                            .--free {
                                font-weight: 700;
                                color: color(alert, Success);
                            }
                            &.--grand {
                                span:first-child {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                    .delivery__address {
                        li {
                            line-height: 2rem;
                        }
                    }
                }
                .--edit {
                    font-size: 1.2rem;
                    color: color(base, Cta);
                    text-decoration: underline;
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .personal-details {
                    margin: 0 0 1rem;
                }
            }
            .checkout-footer {
                margin: 2rem 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 3rem;
                a {
                    display: block;
                    line-height: 3.2rem;
                    font-size: 1.2rem;
                    color: color(base, Cta);
                    @include breakpoint(small only) {
                        margin-right: auto;
                    }
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .cart__side-bar-container {
                .cart__side-bar {
                    @include breakpoint(small only) {
                        margin: 2rem 0 0;
                    }
                }
                ._actions {
                    margin: 2rem 0 0;
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: column;
                    align-items: end;
                    gap: 1rem;
                }
                .totals-container {
                    padding: 2rem;
                    background: color(gray, Gray-100);
                    @include breakpoint(small only) {
                        background: color(gray, Gray-100);
                        order: 3;
                    }
                    .totals-container__totals {
                        position: relative;
                        min-height: 10rem;
                        ._loading {
                            background-color: transparent;
                        }
                        ul {
                            li {
                                display: flex;
                                span {
                                    display: block;
                                    flex: 1;
                                    color: color(base, Black);
                                    font-size: 1.4rem;
                                    line-height: 2.6rem;
                                    &._free {
                                        color: color(alert, Success);
                                    }
                                    &:last-child {
                                        text-align: right;
                                    }
                                }
                                &._grand {
                                    border-top: 1px solid color(gray, Gray-100);
                                    margin: .8rem 0 0;
                                    padding: .8rem 0 0;
                                    span {
                                        font-weight: 600;
                                        font-size: 1.8rem;
                                        &:first-of-type {
                                            flex-basis: 100px;
                                        }
                                    }
                                }
                                small {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }
                }
                .personal-details, .cart__overview {
                    margin: 0 0 2rem;
                    background-color: color(gray, Gray-100);
                    padding: 1.5rem 1rem;
                    h4 {
                        font-weight: 600;
                    }
                    .summary-block {
                        .cart__items {
                            li {
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                                span:last-child {
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                    .--edit {
                        @include primary-font-size;
                        font-weight: 600;
                    }
                }
                .personal-details {
                    &:first-of-type {
                        margin: 0 0 1rem;
                    }
                }
                .cart__overview {
                    margin: 2rem 0;
                }
            }
        }
    }
    footer {
        @include breakpoint(small only) {
            background: color(gray, Gray-100);
        }
        > .grid-container {
            max-width: 72.6rem;
            ul {
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                li {
                    span {
                        display: block;
                        &::after {
                            content: '|';
                            display: inline-flex;
                            margin: 0 1rem;
                        }
                        a {
                            font-size: 1.2rem;
                            @include breakpoint(medium) {
                                font-size: 1.4rem;
                                line-height: 2.2rem;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    &:last-child span::after {
                        display: none;
                    }
                }
            }
            > span {
                margin: 1rem 0 0;
                display: block;
                font-size: 1.2rem;
                a {
                    font-size: 1.2rem;
                }
                @include breakpoint(medium) {
                    display: inline-block;
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    &::before {
                        content: '|';
                        display: inline-flex;
                        margin: 0 1rem;
                    }
                    a {
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.checkout-success {
    margin: 0 0 30rem;
    @include breakpoint(small only) {
        margin: 0 0 10rem;
    }
    h1 {
        @include head-font-style;
    }
    .messages {
        width: 100%;
        margin: 0 0 1rem;
    }
    .order-message {
        margin: 0 0 4rem;
    }
    p {
        font-size: 1.4rem;
    }
    .order__addresses {
        font-size: 1.4rem;
    }
    h4 {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin: 0 0 .5rem;
    }
    .sidebar_right {
        font-size: 1.3rem;
        margin: 2rem 0 0;
        li {
            padding: 0 0 0 3rem;
            line-height: 2.6rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, Green)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 1.4rem;
        }
    }
}

