#offCanvas {
    background: color(gray, Gray-100);
    .modal__header {
        padding: 3rem 3rem 0 3rem;
        display: flex;
        justify-content: space-between;
        .title {
            color: color(gray, Gray-200);
            font-size: 2.4rem;
            line-height: 3rem;
        }
        button {
            .canvas-close {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-200)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
            }
        }
    }
}

.catalog-product-page_body.no-scroll {
    height: 100%;
    overflow-y: hidden !important;
    z-index: 10;
    position: static;
}
