.catalog__category,
.catalog__description {
    margin: 45px 0 0;
    @include breakpoint(small only) {
        margin: 0;
    }
    #offCanvas-filter {
        @include breakpoint(medium) {
            position: sticky;
            position: -webkit-sticky;
            top: 20rem;
            z-index: 1;
        }
    }
    .catalog__category-top {
        padding: 0 10px;
        margin-bottom: 2rem;
        @include breakpoint(small only) {
            padding: 0;
        }
        ._header {
            display: flex;
            align-items: baseline;
            span {
                line-height: 28px;
                padding-left: 10px;
                color: color(gray, Gray-200);
            }
        }
        h1 {
            line-height: 28px;
            font-size: 26px;
            margin: 0 0 20px;
            font-weight: 500;
            color: color(base, Black);
            @include breakpoint(small only) {
                line-height: 24px;
                font-size: 20px;
                margin: 5px 0 15px;
            }
        }
        @include breakpoint(small only) {
            margin: 30px 0 0;
        }
    }
    .catalog__category-image {
        padding: 0 10px;
        position: relative;
        margin: 0 0 40px;
        height: 250px;
        ._content {
            color: white;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(0, -50%);
            padding: 0 40px;
            height: 250px;
            background: lighten(color(base, Black), 5%);
            @include breakpoint(medium up) {
                top: 45%;
            }
            @include breakpoint(large up) {
                top: 50%;
            }
            h1 {
                font-size: 18px;
                color: color(base, White);
            }
            span {
                color: color(base, White);
                p {
                    @include breakpoint(medium up) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                    @include breakpoint(large up) {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
            ._content-text {
                width: 70%;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

            }
        }
        ._image.medium-12.cell {
            padding: 0;
            img {
                height: 250px;
                width: 100%;
                object-fit: cover;
                max-height: 30rem;
            }
        }
    }

    .catalog__category-toolbar-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10px;
        @include breakpoint(small only) {
            padding: 0;
        }
        .toolbar__top {
            display: inline-block;
            @include breakpoint(small only) {
                flex: 100%;
                margin: 0 0 10px;
            }
            .toolbar__filter-container {
                margin-left: auto;
                display: flex;
                @include breakpoint(small only) {
                    flex: 100%;
                }
            }
            .mobile__filter-page-container {
                display: flex;
                width: 100%;
                margin: 15px 0 5px;

                span {
                    width: 50%;
                    font-size: 14px;
                    line-height: 17px;

                    &:nth-of-type(2) {
                        text-align: right;
                    }
                }
            }

            .mobile__filter-container {
                @include breakpoint(small only) {
                    padding: 0 10px 0 0;
                    width: 100%;
                    display: block;
                }
                span {
                    height: 38px;
                    line-height: 38px;
                    display: block;
                    border-radius: 3px;
                    font-size: 12px;
                    border: 1px solid color(gray, Gray-300);
                    padding: 0 15px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-adjustments-horizontal' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Ccircle cx='14' cy='6' r='2' /%3E%3Cline x1='4' y1='6' x2='12' y2='6' /%3E%3Cline x1='16' y1='6' x2='20' y2='6' /%3E%3Ccircle cx='8' cy='12' r='2' /%3E%3Cline x1='4' y1='12' x2='6' y2='12' /%3E%3Cline x1='10' y1='12' x2='20' y2='12' /%3E%3Ccircle cx='17' cy='18' r='2' /%3E%3Cline x1='4' y1='18' x2='15' y2='18' /%3E%3Cline x1='19' y1='18' x2='20' y2='18' /%3E%3C/svg%3E");
                    background-position: right 10px center;
                    background-size: 16px;
                    background-repeat: no-repeat;
                }
            }
            .catalog__category-sort {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding: 0 10px;
                @include breakpoint(small only) {
                    padding: 0 0 0 10px;
                }
            }
            .catalog__category-sorter {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 10px 0;
                @include breakpoint(small only) {
                    width: 100%;
                    margin: 0;
                }
                .off-canvas__filter_toggle {
                    width: 50%;
                }
                .sorting-container {
                    display: flex;
                    align-items: center;
                    @include breakpoint(small only) {
                        width: 100%;
                    }
                    .per-page {
                        display: flex;
                        align-items: center;
                        span {
                            flex-shrink: 1;
                            line-height: 40px;
                            font-size: 12px;
                            color: color(base, Black);
                        }
                        select {
                            flex: 1;
                            outline: none;
                            color: color(base, Black);
                            padding-left: 5px;
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                            font-size: 12px;
                            background-size: 1rem;
                            background-position: right -1.5rem center;
                            border: none;
                            margin-left: 10px;
                            line-height: 34px;
                            height: 34px;
                            @include breakpoint(small only) {
                                border: 1px solid color(gray, Gray-300);
                                border-radius: 3px;
                                font-size: 12px;
                                height: 38px;
                                line-height: 37px;
                                padding-left: 15px;
                                background-size: 12px;
                                margin-left: 0;
                                width: 100%;
                            }
                        }
                    }
                }
                .sort-container {
                    display: flex;
                    align-items:center;
                    @include breakpoint(small only) {
                        width: 100%;
                    }
                    span {
                        flex-shrink: 1;
                        line-height: 40px;
                        font-size: 12px;
                        color: color(base, Black);
                    }
                    select {
                        flex: 1;
                        outline: none;
                        color: color(base, Black);
                        padding-left: 5px;
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArrow down%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Arrow-down' fill='%23303030' fill-rule='nonzero' transform='translate(6.786632, 4.000000) rotate(-90.000000) translate(-6.786632, -4.000000) ' points='10.7866324 -1.57326478 9.57326478 -2.78663239 2.78663239 4 9.57326478 10.7866324 10.7866324 9.57326478 5.21336761 4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
                        font-size: 12px;
                        background-size: 1rem;
                        border: none;
                        line-height: 34px;
                        height: 34px;
                        background-position: right -1.5rem center;
                        @include breakpoint(small only) {
                            border: 1px solid color(gray, Gray-300);
                            border-radius: 3px;
                            font-size: 12px;
                            height: 38px;
                            line-height: 37px;
                            padding-left: 15px;
                            background-size: 12px;
                            margin-left: 0;
                            width: 100%;
                        }
                    }
                }
                .amount {
                    font-size: 12px;
                    line-height: 38px;
                    color: color(gray, Gray-300);
                }
            }
        }
    }
    .catalog__category__active-filter {
        padding: 0 10px;
    }

    .toolbar__bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        @include breakpoint(small only) {
            justify-content: center;
        }
        .product__per__page {
            margin: 0 0 10px;
            text-align: center;
            color: color(base, Black);
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }

    .toolbar__active-filters {
        margin: 0 0 20px;
        ul {
            display: flex;
            li {
                padding-right: 7px;
                label {
                    cursor: pointer;
                    background-color: color(base, Black);
                    color: color(base, White);
                    font-size: 11px;
                    font-weight: 600;
                    line-height: 26px;
                    padding: 0 8px 0 18px;
                    border-radius: 5px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, White)}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
                    background-size: 10px;
                    background-repeat: no-repeat;
                    background-position: center left 4px;
                    &:hover {
                        background-color: darken(color(gray, Gray-300), 5%);
                    }
                }
            }
        }
    }

    .category__description,
    .catalog__description {
        &._bottom {
            margin: 20px 0 40px;
            content-visibility: auto;
            contain-intrinsic-size: 300px;
            @include breakpoint(small only){
                display: flex;
                flex-direction: column;

                h2{
                    margin-top: 20px;
                }
            }
        }
        h2, h3 {
            color: color(base, Black);
            font-weight: 500;
            font-size: 20px;
            margin: 0 0 20px;
        }
        p, ul {
            line-height: 24px;
            font-size: 16px;
            margin: 0 0 20px;
            a {
                color: color(base, Black);
                text-decoration: underline;
                font-weight: 400;
                &:hover {
                    color: darken(color(gray, Gray-300), 5%);
                }
            }
            &:last-child {
                margin: 0;
            }
        }
        ul {
            padding: 0;
            margin: 0 0 20px;
            li {
                list-style: disc inside;
            }
        }
    }
}

.catalog__category-lastviewed {
    .image__holder {
        img {
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
        }
    }

}

.category__info-container {
    h3 {
        line-height: 30px;
        padding: 0 0 15px;
        font-size: 21px;
        font-weight: 500;
        color: color(base, Black);
    }
}

.catalog__category-placeholder {
    .catalog__category-toolbar {
        margin: 10px 0;
        padding: 10px 0;
        ._top {
            display: flex;
            span {
                display: block;
                height: 40px;
                width: 220px;
                background: color(base, Black);
                &:last-of-type {
                    margin-left: auto;
                    width: 70px;
                }
            }
        }
    }
    ._placeholder {
        display: block;
        background: color(base, Black);
        height: 342px;
        margin: 1px 1px 10px;
    }
    .toolbar__bottom {
        ._pagination {
            span {
                background: color(base, Black);
                display: block;
                width: 36px;
                height: 36px;
            }
        }
    }
}

.catalog__description {
    margin-top: 4rem;
    .catalog__image-first {
        img {
            width: 100%;
            object-fit: contain;
            padding: 0 3.5rem 0 0;
        }
    }
    .catalog__image-second {
        > div {
            margin: 0 0 6rem;
            font-size: 1.3rem;
            @include breakpoint(small) {
                h2{
                    margin-top: 20px;
                }
            }
        }
        img {
            padding: 0 0 0 3.5rem;
            width: 100%;
            object-fit: contain;
        }
    }
}
