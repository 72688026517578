@function color($type, $color) {
    @if map-has-key($color-palette, $type) {
        $color-type: map-get($color-palette, $type);
        @if map-has-key($color-type, $color){
            @return map-get($color-type, $color);
        }
        @warn "unknown color `#{$color}` in `#{$type}`";
        @return null;
    }
    @warn "unknown color `#{$type}` in palette";
    @return null;
}

@keyframes scroll-down {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        bottom: -30px;
    }
}
