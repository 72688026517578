.stores-overview {
    margin: 4rem 0 0;
    .store-finder__search {
        width: 80%;
        h1 {
            font-size: 3rem;
            line-height: 3.4rem;
            font-weight: 600;
            margin: 0 0 1rem;
        }
        h3 {
            margin: 2rem 0;
            font-size: 4.6rem;
            line-height: 4.8rem;
            strong {
                font-size: 4.6rem;
                line-height: 4.8rem;
                font-weight: 600;
            }
        }

        ._form {
            display: flex;
            flex-wrap: nowrap;

            button {
                display: inline-block;
                height: 4rem;
                line-height: 4rem;
                font-size: 1.6rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, White)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left 1.8rem center;
                background-size: 1.6rem;
                border-radius: 0 3px 3px 0;

                &:hover {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, Green)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
                }
            }
            input {
                height: 4rem;
                line-height: 4rem;
                width: 80%;
            }
        }
    }
    .product__store-finder-results {
        ul li {
            address {
                margin: 1rem 0 2rem;
                font-style: normal;
                h4 {
                    font-size: 3rem;
                    line-height: 3.6rem;
                }
                p, a {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }
            }
        }
    }
    .product__storefinder-selectedStore {
        margin: 1rem 0 2rem;
        h4 {
            font-size: 3rem;
            line-height: 3.6rem;
        }
    }

    .product__store-finder-results,
    .product__storefinder-selectedStore {
        ._website {
            text-decoration: underline;
        }
    }

    .error-message {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 2rem 0 0;
    }
    .gmnoprint {
        height: 20px;
        line-height: 20px;
        .gm-style-cc {
            height: 20px !important;
            line-height: 20px !important;
        }
    }
    .store__list {
        margin: 0 0 6rem;
    }
}
